import {
  enFlag,
  jpFlag,
  frFlag,
} from '../images'

const activeLanguages = {
  en: {
    img: enFlag,
    name: 'English',
  },
  fr: {
    img: frFlag,
    name: 'Français',
  },
  ja: {
    img: jpFlag,
    name: '日本語',
  },
}

export default activeLanguages

import React from 'react'
import PropTypes from 'prop-types'
import { FadeIn } from '../../'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

const TitleWithSubtitle = ({ data }) => (
  <FadeIn
    animateClass="animate__animated"
    animation="fadeIn"
    offset={-100}
  >
    <div className="mt-5 mb-3" id={data.ScrollTargetId ?? ''}>
      {!data.TitleRichtext && <div className="text-align-center pagecontent-text-block brand-page-content-title">{data.SectionTitle}</div>}
      {data.TitleRichtext && <div className="text-align-center pagecontent-text-block brand-page-content-title"><Markdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>{data.TitleRichtext}</Markdown></div>}
      {data.SubtitleRichtext && <div className="text-align-center pagecontent-text-block brand-page-content-subtitle"><Markdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>{data.SubtitleRichtext}</Markdown></div>}
    </div>
  </FadeIn>
)

TitleWithSubtitle.propTypes = {
  data: PropTypes.object,
}

export default TitleWithSubtitle

import React from 'react'
import { Outlet, useLoaderData } from 'react-router-dom'
import { GoogleAnalytics } from '../../components'

import './index.css'

const MobileAppLayout = () => {
  const { data: { data } } = useLoaderData()

  return (
    <main className="loop-blue-color loop-mobile-app-layout">
      <GoogleAnalytics gaKey="G-Y6015SVNDW" />

      <div className="bg-loop  main-content">
        <Outlet context={data[0].attributes} />
      </div>
    </main>
  )
}

export default MobileAppLayout

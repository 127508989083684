import React from 'react'

export const EcosPin = () => (
  <g
    className="retailer-pin"
    id="Group_1779_2_"
    transform="translate(501.895 321.635)"
  >
    <g id="Group_1769_2_" transform="translate(0)">
      <linearGradient
        gradientTransform="matrix(65.0985 0 0 -55.3762 89254.4531 -737.5372)"
        gradientUnits="userSpaceOnUse"
        id="Path_7753_3_"
        x1="-1384.827"
        x2="-1384.1859"
        y1="-12.1597"
        y2="-11.1604"
      >
        <stop offset="0" style={{ stopColor: '#000000' }} />
        <stop offset="1" style={{ stopColor: '#F0F0F0', stopOpacity: 0 }} />
      </linearGradient>
      <path
        className="st0"
        d="M-854.1-121.5c-17.4,0-37,8.3-43.8,18.6c-12.4,18.5,4.2,40.8,4.2,40.8
        s46.3-22.3,58.6-40.8C-828.2-113.2-836.8-121.5-854.1-121.5z"
        id="Path_7753_2_"
      />
      <g id="Group_762_2_" transform="translate(0 0)">
        <path
          className="st1"
          d="M-893.7-170.5c-24.8,0-45.6,22.8-45.6,41.4c0,33.8,45.6,66.7,45.6,66.7
          s44.3-32.9,44.3-66.7C-849.4-147.7-868.9-170.5-893.7-170.5z"
          id="Path_7754_2_"
        />
      </g>
    </g>
    <g id="ecos" style={{ transform: 'translate(-927px, -136px) scale(0.25)'}}>
      <g fill="#148aff"
        stroke="none"
        transform="translate(0.000000,77.000000) scale(0.100000,-0.100000)"
      >
        <path d="M35 735 l-25 -24 0 -326 0 -326 25 -24 24 -25 236 0 c129 0 235 2
        235 5 0 17 -33 66 -60 89 -31 25 -35 26 -166 26 -75 0 -144 5 -155 10 -16 9
        -19 22 -19 95 l0 85 80 0 c97 0 142 17 170 62 40 66 36 68 -115 68 l-135 0 0
        78 c0 112 1 112 177 112 127 0 143 2 167 21 26 20 56 68 56 89 0 7 -78 10
        -235 10 l-236 0 -24 -25z"
        />
        <path d="M809 745 c-107 -34 -204 -134 -236 -244 -24 -85 -15 -212 22 -286 36
        -75 101 -139 173 -173 50 -24 70 -27 157 -27 88 0 107 3 158 27 61 29 107 63
        139 103 19 24 19 24 -11 40 -45 23 -90 18 -154 -16 -106 -55 -202 -48 -284 24
        -58 51 -78 100 -78 192 0 92 20 141 78 192 82 72 178 79 284 24 65 -34 109
        -39 155 -15 l30 16 -23 26 c-44 49 -64 63 -129 95 -55 27 -77 32 -150 34 -53
        2 -102 -3 -131 -12z"
        />
        <path d="M1569 736 c-101 -41 -163 -99 -208 -196 -23 -48 -26 -68 -26 -155 0
        -93 2 -105 32 -166 39 -79 102 -140 181 -177 50 -24 70 -27 157 -27 87 0 107
        3 157 27 79 37 142 98 181 177 30 61 32 73 32 166 0 88 -3 107 -27 157 -52
        112 -178 205 -291 216 -48 5 -49 4 -42 -19 17 -54 47 -98 124 -182 95 -104
        110 -141 92 -226 -15 -74 -51 -125 -114 -161 -195 -112 -419 99 -322 304 10
        21 50 71 88 110 79 81 94 101 107 145 9 31 8 31 -28 31 -20 0 -62 -11 -93 -24z"
        />
        <path d="M2335 748 c-130 -48 -186 -161 -139 -279 28 -68 85 -100 277 -154 73
        -21 117 -57 117 -96 0 -32 -27 -65 -66 -79 -37 -15 -124 -12 -180 5 -74 22
        -133 19 -168 -8 -35 -28 -30 -39 32 -71 158 -80 372 -74 455 12 59 62 73 156
        35 232 -29 57 -99 100 -211 129 -149 39 -177 58 -177 120 0 33 34 67 79 78 14
        3 64 1 112 -6 96 -14 135 -5 174 38 23 26 20 29 -65 63 -64 25 -226 35 -275
        16z"
        />
      </g>
    </g>
  </g>
)

/* eslint-disable react/jsx-sort-props */
import React from 'react'

export const DrBronnerPin = () => (
  <g
    className="retailer-pin"
    id="Group_1784_2_"
    transform="translate(413.611 510.975)"
  >
    <g id="Group_1780_2_">
      <linearGradient
        id="Path_7753-7_00000136395729456099147670000005978875976321276321_"
        gradientUnits="userSpaceOnUse"
        x1="-1297.8018"
        y1="-201.2258"
        x2="-1297.1605"
        y2="-200.2265"
        gradientTransform="matrix(65.0985 0 0 -55.3762 83507.2969 -11222.4707)"
      >
        <stop offset="0" style={{ stopColor: '#000000' }} />
        <stop offset="1" style={{ stopColor: '#F0F0F0', stopOpacity: 0 }} />
      </linearGradient>
      <path
        id="Path_7753-7_2_"
        style={{
          fill:
            'url(#Path_7753-7_00000136395729456099147670000005978875976321276321_)',
        }}
        d="
      M-936.1-136.7c-17.4,0-37,8.3-43.8,18.6c-12.4,18.5,4.2,40.8,4.2,40.8s46.3-22.3,58.6-40.8
      C-910.2-128.3-918.7-136.7-936.1-136.7z"
      />
      <g id="Group_762-7_2_" transform="translate(0 0)">
        <path
          id="Path_7754-7_2_"
          className="st1"
          d="M-975.7-185.6c-24.8,0-45.6,22.8-45.6,41.4c0,33.8,45.6,66.7,45.6,66.7
        s44.3-32.9,44.3-66.7C-931.3-162.9-950.8-185.6-975.7-185.6z"
        />
      </g>
    </g>
    <g id="drbronner" style={{ transform: 'translate(-1018px, -96px) scale(0.03) rotateX(180deg)'}}>
      <g
        fill="#07358f"
        stroke="none"
      >
        <path d="M604 1849 c-9 -16 12 -33 31 -25 14 5 15 9 5 21 -15 18 -26 19 -36 4z" />
        <path d="M385 1810 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"
        />
        <path d="M845 1810 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"
        />
        <path d="M350 1760 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path d="M880 1759 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path d="M450 1740 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path d="M775 1740 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"
        />
        <path d="M560 1701 c-46 -15 -85 -39 -117 -73 -114 -119 -69 -329 84 -393 57
-24 150 -19 210 10 176 88 176 346 -1 437 -43 22 -136 32 -176 19z m0 -22 c0
-12 -67 -42 -83 -36 -6 2 9 13 34 25 51 26 49 25 49 11z m223 -63 c44 -41 57
-59 40 -53 -10 3 -12 -13 -5 -32 3 -7 0 -22 -6 -32 -7 -10 -9 -25 -6 -34 4 -8
1 -15 -6 -15 -10 0 -10 -3 0 -15 6 -8 9 -19 5 -25 -3 -5 4 -14 17 -19 16 -7
18 -10 6 -10 -20 -1 -50 27 -43 39 6 10 -15 60 -25 60 -16 0 -31 -30 -26 -52
3 -13 3 -17 0 -10 -3 6 -9 10 -14 7 -4 -3 -11 6 -14 19 -4 15 -17 28 -37 35
-40 14 -74 14 -40 0 21 -8 23 -12 10 -23 -8 -8 -23 -17 -35 -20 -16 -5 -24 1
-38 30 -9 20 -19 34 -21 32 -10 -9 28 -71 46 -75 24 -7 24 -18 -1 -43 -11 -11
-20 -28 -20 -38 0 -10 -5 -23 -12 -30 -7 -7 -8 -12 -4 -12 5 0 3 -7 -4 -15
-33 -39 -73 -3 -84 75 -5 36 -12 48 -36 62 -24 14 -30 24 -30 52 0 25 6 40 20
48 11 7 20 21 20 30 0 10 7 18 15 18 13 0 19 11 17 32 -1 3 5 9 13 12 9 3 12
0 8 -10 -4 -10 0 -12 11 -8 8 3 14 11 13 17 -2 12 61 28 68 17 3 -5 19 -5 35
0 17 5 30 6 30 4 0 -3 7 2 16 10 28 29 67 19 117 -28z m48 -209 c-8 -8 -11 -7
-11 4 0 20 13 34 18 19 3 -7 -1 -17 -7 -23z m9 -44 c-1 -5 -12 -21 -25 -38
-30 -36 -34 -19 -4 19 21 26 29 32 29 19z m-227 -53 c-3 -11 -9 -20 -14 -20
-10 0 -12 24 -2 33 13 13 21 7 16 -13z"
        />
        <path d="M535 1610 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path d="M459 1549 c-12 -7 -11 -9 2 -9 9 0 19 -7 23 -15 6 -17 66 -21 66 -5
0 9 -14 14 -32 11 -5 0 -8 5 -8 12 0 15 -30 19 -51 6z"
        />
        <path d="M545 1550 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"
        />
        <path d="M105 1670 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"
        />
        <path d="M1120 1670 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path d="M1332 1623 c3 -41 6 -48 25 -51 24 -3 63 29 63 52 0 24 -30 46 -61
46 -30 0 -30 -1 -27 -47z m56 0 c2 -11 -4 -23 -12 -26 -12 -5 -16 1 -16 24 0
35 23 36 28 2z"
        />
        <path d="M1450 1620 c0 -27 5 -50 10 -50 6 0 10 7 10 17 0 14 2 14 20 -2 23
-21 39 -13 20 10 -9 11 -10 18 -1 29 18 22 2 46 -30 46 -28 0 -29 -2 -29 -50z
m40 20 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z"
        />
        <path d="M1630 1621 c0 -54 10 -62 51 -41 19 11 27 40 11 40 -5 0 -8 10 -8 23
1 17 -5 23 -26 25 -27 3 -28 2 -28 -47z m40 25 c0 -3 -4 -8 -10 -11 -5 -3 -10
-1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m0 -46 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"
        />
        <path d="M1730 1620 c0 -27 5 -50 10 -50 6 0 10 7 10 17 0 14 2 14 20 -2 23
-21 39 -13 20 10 -9 11 -10 18 -1 29 18 22 2 46 -30 46 -28 0 -29 -2 -29 -50z
m40 20 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z"
        />
        <path d="M1828 1645 c-24 -53 46 -100 85 -57 36 39 15 82 -40 82 -26 0 -36 -6
-45 -25z m70 -19 c4 -21 -14 -37 -35 -29 -14 5 -18 35 -6 47 12 12 38 1 41
-18z"
        />
        <path d="M1960 1620 c0 -27 5 -50 10 -50 6 0 10 15 10 33 l1 32 27 -33 c15
-18 30 -32 35 -32 4 0 7 23 7 50 0 28 -4 50 -10 50 -5 0 -10 -12 -10 -27 l-1
-28 -22 28 c-34 41 -47 35 -47 -23z"
        />
        <path d="M2090 1620 c0 -27 5 -50 10 -50 6 0 10 15 10 33 l0 32 26 -32 c14
-18 30 -33 35 -33 5 0 9 23 9 50 0 28 -4 50 -10 50 -5 0 -10 -12 -10 -27 l-1
-28 -24 28 c-35 39 -45 34 -45 -23z"
        />
        <path d="M2212 1623 c3 -44 5 -48 31 -51 15 -2 27 1 27 7 0 6 -7 11 -15 11 -8
0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15 5
-15 10 0 6 7 10 15 10 8 0 15 5 15 10 0 6 -14 10 -31 10 -30 0 -30 -1 -27 -47z"
        />
        <path d="M2300 1621 c0 -28 5 -51 10 -51 6 0 10 7 10 15 0 8 4 15 9 15 5 0 11
-7 15 -15 3 -8 12 -15 21 -15 14 0 14 3 -1 25 -11 17 -12 25 -4 25 6 0 10 10
8 23 -2 17 -11 23 -35 25 l-33 3 0 -50z m40 19 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"
        />
        <path d="M2400 1651 c-6 -12 -7 -21 -1 -21 10 0 24 30 17 37 -2 3 -9 -5 -16
-16z"
        />
        <path d="M2457 1663 c-14 -13 -6 -42 13 -48 11 -4 18 -11 14 -16 -3 -6 -12 -8
-20 -5 -7 3 -16 1 -19 -4 -7 -12 28 -22 49 -14 25 9 20 50 -6 58 l-23 7 23 9
c12 5 22 12 22 15 0 7 -45 6 -53 -2z"
        />
        <path d="M290 1650 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path d="M940 1650 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
        />
        <path d="M1550 1585 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"
        />
        <path d="M220 1529 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path d="M1010 1529 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path d="M1943 1463 c-41 -8 -53 -40 -53 -139 0 -116 14 -139 85 -139 71 0 85
23 85 137 0 49 -4 98 -10 108 -8 16 -63 42 -79 39 -3 -1 -16 -4 -28 -6z m62
-66 c3 -12 5 -54 3 -92 l-3 -70 -30 0 -30 0 -3 79 c-3 95 0 106 32 106 18 0
27 -7 31 -23z"
        />
        <path d="M1275 1448 c-2 -7 -16 -60 -30 -118 -14 -58 -28 -113 -31 -122 -4
-14 1 -18 20 -18 19 0 27 7 32 25 5 21 12 25 45 25 33 0 40 -4 45 -25 4 -18
13 -25 31 -25 16 0 23 5 20 13 -2 6 -17 67 -32 135 l-28 122 -34 0 c-18 0 -36
-6 -38 -12z m51 -108 l11 -50 -27 0 -27 0 11 50 c5 28 13 50 16 50 3 0 11 -22
16 -50z"
        />
        <path d="M1450 1325 l0 -135 65 0 c58 0 65 2 65 20 0 17 -7 20 -40 20 l-40 0
0 115 0 115 -25 0 -25 0 0 -135z"
        />
        <path d="M1620 1325 l0 -135 65 0 c58 0 65 2 65 20 0 17 -7 20 -40 20 l-40 0
0 115 0 115 -25 0 -25 0 0 -135z"
        />
        <path d="M2120 1325 l0 -135 25 0 c23 0 24 2 27 81 l3 81 35 -81 c32 -73 38
-81 63 -81 l27 0 0 135 0 135 -25 0 c-24 0 -25 -2 -26 -77 l-1 -78 -36 78
c-32 70 -38 77 -64 77 l-28 0 0 -135z"
        />
        <path d="M2360 1325 l0 -135 70 0 c63 0 70 2 70 20 0 17 -7 20 -46 20 l-45 0
3 38 c3 32 7 37 31 40 19 2 27 9 27 23 0 14 -7 19 -30 19 -28 0 -30 3 -30 35
0 35 0 35 45 35 38 0 45 3 45 20 0 18 -7 20 -70 20 l-70 0 0 -135z"
        />
        <path d="M2570 1375 c0 -68 3 -87 15 -92 9 -3 17 -4 19 -2 2 2 6 43 10 92 l7
87 -25 0 c-26 0 -26 -1 -26 -85z"
        />
        <path d="M982 1401 c101 -90 77 -202 -57 -265 -70 -34 -85 -33 -20 1 131 67
161 159 76 236 -22 20 -50 41 -63 47 -13 7 -7 -2 15 -20 76 -64 85 -129 24
-188 -30 -29 -104 -68 -113 -59 -2 2 14 11 36 21 51 23 100 79 100 116 0 31
-38 83 -74 102 -17 9 -16 6 7 -19 33 -35 36 -88 8 -125 -11 -14 -48 -38 -83
-53 -62 -28 -93 -32 -206 -25 -29 2 -48 -3 -62 -15 -28 -25 -16 -35 33 -29 34
5 49 1 76 -18 36 -24 57 -67 46 -96 -5 -14 -7 -14 -15 -2 -7 11 -10 8 -10 -12
0 -31 -16 -38 -23 -10 -4 13 -5 12 -6 -5 -1 -27 -21 -42 -21 -16 0 14 -3 12
-13 -7 -8 -14 -13 -27 -11 -28 5 -8 75 32 84 47 24 41 43 52 114 71 220 56
316 191 223 313 -13 17 -44 43 -68 56 l-44 24 47 -42z"
        />
        <path d="M228 1390 c-21 -17 -44 -49 -54 -75 -22 -59 -8 -110 43 -161 37 -37
134 -88 188 -99 17 -3 3 5 -31 18 -120 46 -194 122 -194 199 0 37 34 96 73
129 12 10 20 18 17 19 -3 0 -22 -14 -42 -30z"
        />
        <path d="M251 1365 c-17 -20 -31 -41 -31 -48 0 -7 6 -3 12 8 6 11 22 32 36 48
14 15 23 27 20 27 -3 0 -20 -16 -37 -35z"
        />
        <path d="M306 1358 c-22 -31 -20 -80 4 -111 21 -27 114 -78 139 -76 9 0 -3 7
-27 16 -53 19 -122 82 -122 112 0 12 7 35 15 51 18 35 13 39 -9 8z"
        />
        <path d="M1757 1343 c-19 -19 -5 -33 33 -33 33 0 40 3 40 20 0 16 -7 20 -33
20 -19 0 -37 -3 -40 -7z"
        />
        <path d="M251 1295 c-1 -27 31 -95 44 -95 3 0 -2 12 -11 26 -10 14 -21 42 -25
62 -6 35 -7 35 -8 7z"
        />
        <path d="M212 1275 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
        <path d="M220 1233 c0 -14 55 -70 83 -84 12 -6 5 3 -15 21 -21 17 -45 41 -53
53 -8 12 -15 16 -15 10z"
        />
        <path d="M2564 1225 c-4 -9 0 -23 9 -32 21 -21 49 -9 45 19 -4 28 -45 38 -54
13z"
        />
        <path d="M478 1163 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path d="M528 1153 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path d="M510 1023 c0 -25 3 -33 9 -25 6 10 10 8 16 -10 7 -22 7 -22 15 7 l8
30 1 -32 c1 -37 21 -56 21 -21 0 13 3 34 7 47 5 20 3 23 -10 18 -11 -4 -14 -2
-10 9 7 20 -14 18 -26 -3 -10 -17 -10 -17 -11 0 0 9 -4 17 -10 17 -5 0 -10
-17 -10 -37z"
        />
        <path d="M448 1043 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path d="M590 975 c0 -19 5 -35 10 -35 6 0 10 16 10 35 0 19 -4 35 -10 35 -5
0 -10 -16 -10 -35z"
        />
      </g>
    </g>
  </g>
)

import France from '../../images/shop/country-selectors/France-badge.svg'
import FranceFlag from '../../images/shop/country-selectors/France-flag.png'
import Japan from '../../images/shop/country-selectors/Japan-badge.svg'
import JapanFlag from '../../images/shop/country-selectors/Japan-flag.png'

// const australiaDetails = {
//   flag: AustraliaFlag,
//   img: Australia,
//   name: 'Australia',
//   nameWithPrefix: 'Australia',
//   order: 5,
//   text: 'Australia',
// }

// const canadaDetails = {
//   flag: CanadaFlag,
//   img: Canada,
//   name: 'Canada',
//   nameWithPrefix: 'Canada',
//   order: 0,
//   text: 'Canada' ,
// }

const franceDetails = {
  flag: FranceFlag,
  img: France,
  key: 'fr',
  name: 'France',
  nameWithPrefix: 'France',
  order: 3,
  text: 'France',
}

const japanDetails = {
  flag: JapanFlag,
  img: Japan,
  key: 'ja',
  name: 'Japan',
  nameWithPrefix: 'Japan',
  order: 4,
  text: 'Japan' ,
}

// const ukDetails = {
//   flag: UKFlag,
//   img: UK,
//   name: 'UK',
//   nameWithPrefix: 'the U.K.',
//   order: 2,
//   text: 'United Kingdom',
// }

// const usDetails = {
//   flag: USFlag,
//   img: US,
//   key: 'en',
//   name: 'US',
//   nameWithPrefix: 'the U.S.',
//   order: 1,
//   text: 'United States',
// }

/* eslint-disable sort-keys */
export const countryDetails = {
  // australia: australiaDetails,
  // canada: canadaDetails,
  // us: usDetails,
  france: franceDetails,
  japan: japanDetails,
  // uk: ukDetails,
}

import { useEffect } from 'react'
import PropTypes from 'prop-types'

import ReactGA from 'react-ga4'

const CookieAlert = ({ gaKey = 'G-SFFJWZ4BHT' }) => {
  useEffect(() => {
    ReactGA.initialize(gaKey, {
      gaOptions: {
        anonymizeIp: false,
        cookieExpires: 31536000,
      },
      testMode: false,
    })
  }, [])

  return null
}

CookieAlert.propTypes = {
  gaKey: PropTypes.string,
}

export default CookieAlert

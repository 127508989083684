import React from 'react'
import Obfuscate from 'react-obfuscate'
import { useLoaderData } from 'react-router-dom'
import { Form, SEO } from '../../components'
import './waiting-list.css'

const WaitingListPage = () => {
  const { contactList, signupForm } = useLoaderData()

  return (
    <>
      <SEO title="Waiting List" />

      <div className="form-page">
        <Form
          backendUrl={process.env.REACT_APP_BACKEND_URL}
          captchaSiteKey={process.env.REACT_APP_GATSBY_CAPTCHA_CLIENT_KEY}
          signUpForm={signupForm.attributes}
        />

        <div className="form-footer-wrapper">
          <div className="form-contact">
            <ul>
              {(contactList ?? []).map(({ attributes }) => (
                <li key={attributes.Email}>
                  {attributes.Label}: <Obfuscate className="nav-link" email={attributes.Email} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default WaitingListPage

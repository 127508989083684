import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import uniqueId from 'lodash.uniqueid'
import { Map } from '../'
import { defaultMapPinColor } from '../../constants'
import { caretDown } from '../../images'

import './index.css'

import strapiInstance from '../../api'

const RetailerLocations = ({ country }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [brands, setBrands] = useState(undefined)
  const [locations, setLocations] = useState(undefined)
  const [filteredLocations, setFilteredLocations] = useState(undefined)
  const [retailers, setRetailers] = useState(undefined)
  const [retailerListOpen, setRetailerListOpen] = useState(false)
  const [selectedRetailer, setSelectedRetailer] = useState(undefined)
  const { i18n } = useTranslation()

  useEffect(() => {
    const fetchBrandPages = async () => {
      const { data } = await strapiInstance.get(`/brand-pages?populate=*&pagination[pageSize]=1000&locale=${i18n.language}`)

      if (data) {
        setBrands(data.data)
      }

      setIsLoading(false)
    }

    fetchBrandPages()
  }, [i18n.language])

  useEffect(() => {
    const filterData = () => {
      const tempFilteredData = brands.filter(({ attributes }) =>
        attributes.Country?.Countries?.includes(country.name) &&
      attributes.LocationInfo?.locations,
      )

      const tempRetailers = []
      const tempLocations = []

      tempFilteredData.forEach(retailer => {
        tempRetailers.push({
          brand: retailer.attributes.BrandName,
          color: retailer.attributes.PinColor,
          id: retailer.id,
        })

        retailer.attributes.LocationInfo?.locations.forEach(location => {
          tempLocations.push({
            ...location,
            brandId: retailer.id,
            color: retailer.attributes.PinColor,
          })
        })
      })

      setRetailers(tempRetailers)
      setLocations(tempLocations)
      setFilteredLocations(tempLocations)
    }
    if (brands && country) {
      filterData()
    }
  }, [country, brands])

  useEffect(() => {
    if (selectedRetailer) {
      const tempFilteredRetailerPoints = locations.filter(locationPoint => locationPoint.brandId === selectedRetailer.id)

      setFilteredLocations(tempFilteredRetailerPoints)
    } else {
      setFilteredLocations(locations)
    }
  }, [locations, selectedRetailer])

  const selectRetailer = retailer => {
    const tempFilteredRetailerPoints = locations.filter(locationPoint => locationPoint.brandId === retailer.id)

    setRetailerListOpen(false)
    setSelectedRetailer(retailer)

    setFilteredLocations(tempFilteredRetailerPoints)
  }

  const resetRetailerSelection = () => {
    setRetailerListOpen(false)
    setSelectedRetailer(undefined)
    setFilteredLocations(locations)
  }

  if (isLoading || !retailers || retailers.length === 0) return null

  return (
    <div>
      <div className="shop-page-subtitle mb-4">
        <Trans>availableLocations</Trans>
      </div>
      <div className="location-map-container">
        <div className="legend-container">
          <div className="retailer-selector-group">
            <div
              className="retailer-selector"
              onClick={() => setRetailerListOpen(!retailerListOpen)}
              value={selectedRetailer}
            >
              <div className="retailer-selector-opener">
                {!selectedRetailer || retailerListOpen ? (
                  <><Trans>selectRetailer</Trans></>
                ) : (
                  <>
                    <div
                      className="retailer-color-badge"
                      style={{
                        backgroundColor:
                          selectedRetailer.color || defaultMapPinColor,
                      }}
                    />
                    <div>{selectedRetailer.brand}</div>
                  </>
                )}
                <img
                  alt="caret-down"
                  className={`retailer-selector-caret ${
                    retailerListOpen ? 'rotated' : ''
                  }`}
                  src={caretDown}
                />
              </div>
            </div>

            {retailerListOpen && (
              <div>
                <hr />
                {retailers.map(retailer => (
                  <div
                    className="retailer-selector"
                    key={uniqueId()}
                    onClick={() => selectRetailer(retailer)}
                  >
                    <div className="retailer-color-badge"
                      style={{
                        backgroundColor: retailer.color || defaultMapPinColor,
                      }}
                    />
                    <div>{retailer.brand}</div>
                  </div>
                ))}
                <hr />
                <div
                  className="retailer-selector show-all-field"
                  onClick={resetRetailerSelection}
                >
                  <Trans>reset</Trans>
                </div>
              </div>
            )}
          </div>
        </div>

        <Map closeFilter={() => setRetailerListOpen(false)} markers={filteredLocations} />
      </div>
    </div>
  )
}

RetailerLocations.propTypes = {
  country: PropTypes.object,
}

export default RetailerLocations

import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { Button } from 'react-bootstrap'
import { FadeIn, Link, Separator, SEO } from '../../components'
import { ourMissionContent } from '../../elements/purpose/our-mission-content'
import purposeImages from '../../images/purpose'

import './index.css'

const PurposePage = () => (
  <>
    <SEO title="Purpose" />

    <IntroSection />
    <OurStorySection />
    <OurRootsSection />
    <TCFoundationSection />

    <div className="container-lg">
      <div className="join-us-section">
        <div className="main-section-title our-mission-title primary-title"><Trans>ourMission</Trans></div>
        {ourMissionContent.map((block, index) => (
          <MissionSectionBlock
            block={block}
            key={block.title}
            picOnTheLeft={index % 2 === 1}
          />
        ))}
      </div>
    </div>
  </>
)

const IntroSection = () => (
  <div className="main-section-background">
    <div className="main-section first-page-section">
      <div className="container-lg main-section-content">
        <div className="main-section-part padded-text-content purpose-section-part">
          <FadeIn horizontal>
            <div className="main-section-title primary-title">
              <Trans>eliminatingTheIdeaOfWaste</Trans>
            </div>
          </FadeIn>
          <FadeIn delay={600}>
            <div className="main-section-subtitle"><Trans>meetLoop</Trans></div>
            <div className="main-section-text primary-text">
              <Trans>loopIsAGlobalPlatforml</Trans>
            </div>
          </FadeIn>
        </div>
        <div className="main-section-part second-section-part purpose-second-section-part">
          <FadeIn delay={1000} horizontal>
            <img
              alt="Loop shelf"
              className="section-image padded-image-content"
              src={purposeImages.loopDisplay}
            />
          </FadeIn>
        </div>
      </div>
    </div>
  </div>
)

const OurStorySection = () => (
  <div className="main-section-background our-story-background">
    <div className="main-section">
      <div className="container-lg main-section-content reversed-purpose-section">
        <div className="main-section-part padded-text-content purpose-section-part">
          <FadeIn horizontal>
            <div className="purpose-section-title"><Trans>ourStory</Trans></div>
            <Separator
              style={{ backgroundColor: '#FFFFFF', marginBottom: '15px' }}
            />
            <div className="main-section-text purpose-text">
              <Trans>loopSGroundbreaking</Trans>
            </div>
          </FadeIn>
        </div>
        <div className="main-section-part second-section-part purpose-second-section-part">
          <FadeIn delay={600}>
            <img
              alt="World Economic Forum"
              className="section-image"
              src={purposeImages.ourStory}
            />
          </FadeIn>
        </div>
      </div>
    </div>
  </div>
)

const OurRootsSection = () => (
  <div className="main-section-background our-roots-background">
    <div className="main-section">
      <div className="container-lg main-section-content">
        <div className="main-section-part padded-text-content purpose-section-part">
          <FadeIn horizontal>
            <div className="purpose-section-title"> <Trans>ourRoots</Trans></div>
            <Separator
              style={{ backgroundColor: '#076268', marginBottom: '15px' }}
            />
            <div className="main-section-subtitle">
              <Trans>whereWeStartedTerracycle</Trans>
            </div>
            <div className="main-section-text purpose-text">
              <Trans>loopGlobalHoldings</Trans>
            </div>
            <br />
            <div className="main-section-text purpose-text">
              <Trans>with18YearsOfExperienceIn</Trans>
            </div>
            <Link externalLink="https://www.terracycle.com/">
              <Button
                className="main-button"
                style={{ backgroundColor: '#076268' }}
                variant="primary"
              >
                <Trans>learnMore</Trans>
              </Button>
            </Link>
          </FadeIn>
        </div>
        <div className="main-section-part second-section-part purpose-second-section-part">
          <FadeIn delay={600}>
            <img
              alt="TerraCycle logo"
              className="section-image"
              src={purposeImages.terracycleLogo}
            />
          </FadeIn>
        </div>
      </div>
    </div>
  </div>
)

const TCFoundationSection = () => (
  <div className="main-section-background tc-foundation-background">
    <div className="main-section">
      <div className="container-lg main-section-content reversed-purpose-section">
        <div className="main-section-part padded-text-content purpose-section-part">
          <FadeIn horizontal>
            <div className="purpose-section-title"><Trans>ourFoundation</Trans></div>
            <Separator
              style={{ backgroundColor: '#FFFFFF', marginBottom: '15px' }}
            />
            <div className="main-section-subtitle">
              <Trans>whatWeStandForTerracycleFoundation</Trans>
            </div>
            <div className="main-section-text purpose-text">
              <Trans>theTerracycleGlobal</Trans>
            </div>
            <Link externalLink="https://www.terracyclefoundation.org/">
              <Button variant="light"><Trans>learnMore</Trans></Button>
            </Link>
          </FadeIn>
        </div>
        <div className="main-section-part second-section-part purpose-second-section-part">
          <FadeIn delay={600}>
            <img
              alt="TerraCycle Foundation"
              className="section-image"
              src={purposeImages.tcFoundation}
            />
          </FadeIn>
        </div>
      </div>
    </div>
  </div>
)

const MissionSectionBlock = ({ block, picOnTheLeft }) => (
  <div
    className={`main-section-content ${
      picOnTheLeft && 'reversed-purpose-section'
    } mission-section-block`}
  >
    <div className="main-section-part padded-text-content purpose-section-part">
      <FadeIn horizontal>
        <div className="main-section-title our-mission-block-title">
          <Trans>{block.title}</Trans>
        </div>
        <Separator style={{ backgroundColor: '#2d9cdb', borderTop: 'none', height: '1px', marginBottom: '25px' }} />
        {block.text.map(text =>
          <div className="our-mission-block-text" key={text}>
            <Trans>{text}</Trans>
          </div>,
        )}
      </FadeIn>
    </div>
    <div className="main-section-part second-section-part purpose-second-section-part">
      <FadeIn delay={600}>
        <img
          alt={block.title}
          className={`section-image ${
            picOnTheLeft && 'reversed-section-image'
          }`}
          src={block.image}
        />
      </FadeIn>
    </div>
  </div>
)

MissionSectionBlock.propTypes = {
  block: PropTypes.object,
  picOnTheLeft: PropTypes.bool,
}

export default PurposePage

import React from 'react'
import PropTypes from 'prop-types'
import { Link, useParams } from 'react-router-dom'

const LinkComponent = ({ className, externalLink = '', path, children, isDirectLink = false }) => {
  const { urlLang } = useParams()
  const urlPrefix = urlLang ? `/${urlLang}` : ''

  /* eslint-disable no-extra-boolean-cast */
  if (externalLink.includes('mailto:')) {
    return (
      <a
        className={className}
        href={externalLink}
        rel="noreferrer"
        target="_blank"
      >
        {children}
      </a>
    )
  }

  return (!!~externalLink.indexOf('http')) ?
    <a
      className={className}
      href={externalLink}
      rel="noreferrer"
      target="_blank"
    >
      {children}
    </a>
    :
    (isDirectLink) ?
      <a
        className={className}
        href={path}
        rel="noreferrer"
      >
        {children}
      </a>
      :
      <Link
        className={className}
        to={`${urlPrefix}${path}`}
      >
        {children}
      </Link>

}

LinkComponent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  externalLink: PropTypes.string,
  isDirectLink: PropTypes.bool,
  path: PropTypes.string,
}

export default LinkComponent

import React from 'react'
import { SEO } from '../../components'
import './index.css'

const DsarPage = () => (
  <>
    <SEO title="Data Subject Access Request" />
    <div className="container-lg dsar-page">
      <iframe src="https://privacyportal.onetrust.com/webform/b8c23cad-e1f0-4b9a-b984-072a1c303c05/9cd35b9b-e6a1-4b1e-b25f-5ae2b3e98e73" title="dsar" />
    </div>
  </>
)

export default DsarPage

import React from 'react'
import PropTypes from 'prop-types'
import { FadeIn, Link } from '../../'

const ImageWithTextOverlay = ({ data }) => (
  <div className="col-md-12 delivery-box">
    <FadeIn delay={400}>
      <Link
        className="delivery-link text-decoration-none"
        externalLink={data.OverlayUrl ?? ''}
        style={{ fontWeight: 'bold', pointerEvents: data.OverlayUrl ? 'auto' : 'none' }}
      >
        {data.OverlayText}
      </Link>

      <img
        alt={data.Images.DesktopImage?.data.attributes.alternativeText || ''}
        className="img-fluid d-none d-md-block"
        src={data.Images.DesktopImage?.data.attributes.url}
        style={{ height: 'auto', width: '100%' }}
      />

      <img
        alt={data.Images.MobileImage?.data.attributes.alternativeText || ''}
        className="img-fluid d-md-none"
        src={data.Images.MobileImage?.data.attributes.url}
      />
    </FadeIn>
  </div>
)

ImageWithTextOverlay.propTypes = {
  data: PropTypes.object,
}

export default ImageWithTextOverlay

import axios from 'axios'

const strapiInstance = axios.create({
  baseURL: 'https://exploreloop.loopcms.app/api',
})

strapiInstance.defaults.headers.post['Content-Type'] = 'application/json'
strapiInstance.defaults.headers.common['Authorization'] = 'Bearer 8a4be82801b2c1761e485836d45f6c9c2d612c60f2f6a3b84cf67535639df409cfcb4efe49829911d4089ea36407aab133c0834f4ca7813fec7eb9d9cfc22149b85a28c2802ff52490856bb92e7d6a2d8a52191efce29a95a0c7be6fc3bfcf019ec4f7a0741de331b9fdee705b11b97327816e068b8b49d62e519dbc36ce2166'

export default strapiInstance

import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Carousel } from 'nuka-carousel'
import { Arrow } from '../'

import './index.scss'

const BrandBar = ({ className = '', data }) => {
  const ref = useRef()
  const imagesData = data
    .map(images => images.attributes?.formats?.small?.url || images.attributes?.formats?.thumbnail?.url || images.attributes?.formats?.medium?.url || images.attributes?.url)
    .flat() || null

  const Arrows = () => (
    <div className="home-brand-slider-arrow-container">
      <Arrow className="home-brand-slider-arrow home-brand-slider-left-arrow" direction="left" onClick={() => ref.current.goBack()} />
      <Arrow className="home-brand-slider-arrow home-brand-slider-right-arrow" direction="right" onClick={() => ref.current.goForward()} />
    </div>
  )

  let mobileBrands = []

  for (let i = 0; i < imagesData.length; i+= 16) {
    const temparray = imagesData.slice(i, i + 16)
    mobileBrands.push(temparray)
  }

  const DesktopBrandCarousel = ({ brands }) => (
    <Carousel
      arrows={<Arrows />}
      autoplay={true}
      autoplayInterval={3000}
      className={`our-brands-slider ${className}`}
      ref={ref}
      scrollDistance="screen"
      showArrows
      wrapMode="wrap"
    >
      {React.Children.toArray(
        (brands || []).map(brandUrl => (
          <img
            alt="brand-logo"
            className="brand-logo"
            src={brandUrl}
          />
        )),
      )}
    </Carousel>
  )

  const MobileBrandCarousel = ({ brands }) => (
    <Carousel
      autoplay={true}
      autoplayInterval={3000}
      className="mobile-brand-slider"
    >
      {React.Children.toArray(
        (brands || []).map(brand => (
          <div className="mobile-brand-slide">
            {React.Children.toArray(
              brand.map(image => (
                <img
                  alt="brand logo"
                  className="mobile-brand-logo"
                  src={image}
                  style={{ height: '100%', objectFit: 'contain', width: '100%' }}
                />
              )),
            )}
          </div>
        )),
      )}
    </Carousel>
  )

  return (
    <>
      <div className="brand-bar">
        <div className="our-brands-logo-container desktop-home-brands">
          <DesktopBrandCarousel brands={imagesData} />
        </div>
      </div>
      <div className="our-brands-logo-container mobile-home-brands">
        <MobileBrandCarousel brands={mobileBrands} />
      </div>
    </>
  )
}

BrandBar.propTypes = {
  brands: PropTypes.array,
  className: PropTypes.string,
  data: PropTypes.array,
}

export default BrandBar

import React from 'react'
import PropTypes from 'prop-types'

import './index.scss'

const SliderImages = ({ images }) => {
  return images.map((image) => (
    <div className="carousel-card" key={image.id}>
      <img alt={image.attributes.name} src={image.attributes.url} />
    </div>
  ))
}

const Slider = ({ images }) => (
  <div className="carousel-container">
    <div className="carousel-track">
      <SliderImages images={images} />
      <SliderImages images={images} />
    </div>
  </div>
)

SliderImages.propTypes = {
  images: PropTypes.array,
}

Slider.propTypes = {
  images: PropTypes.array,
}

export default Slider

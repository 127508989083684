import React from 'react'
import PropTypes from 'prop-types'
import './panel.css'

const Panel = ({ anchorUrlName, children, heading, panelStyle }) => (
  <div className="panel-wrapper" style={{ ...panelStyle }}>
    {heading && (
      <h2 className="panel-heading" id={anchorUrlName} name={anchorUrlName}>
        {heading}
      </h2>)
    }
    {children}
  </div>
)

Panel.propTypes = {
  anchorUrlName: PropTypes.string,
  children: PropTypes.node,
  heading: PropTypes.string,
  panelStyle: PropTypes.object,
}

export default Panel

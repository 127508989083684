import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const SEO = ({ lang = 'en', title }) => (
  <Helmet
    htmlAttributes={{
      lang,
    }}
    title={title}
  />
)

SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default SEO

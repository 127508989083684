/* eslint-disable */
import React, { Component } from 'react'
import { Controller, Scene } from 'react-scrollmagic'
import { Timeline, Tween } from 'react-gsap'
import { heinzTop, heinzBottom, heinzFull } from '../../images'
import homeImages from '../../images/home'

import './animation.css'

let resizeTimer

const getPosition = (element) => {
  let xPosition = 0
  let yPosition = 0
  const height = element?.getBoundingClientRect()?.height
  const width = element?.getBoundingClientRect()?.width

  while(element) {
    xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft)
    yPosition += (element.offsetTop - element.scrollTop + element.clientTop)
    element = element.offsetParent
  }

  return {
    height,
    width,
    x: xPosition,
    y: yPosition,
  }
}

class Animation extends Component {
  constructor(props) {
    super(props)
    this.cancelableGetEntry = null
    this.state = {
      isLoading: true,
    }
  }

  componentDidMount() {
    if (document.readyState === 'complete') {
      setTimeout(this.onResize, 300)
    } else {
      window.addEventListener('load', this.initAnimation)
    }
    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.initAnimation)
    window.removeEventListener('resize', this.onResize)
  }

  onResize = () => {
    this.setState({
      isLoading: true
    })

    clearTimeout(resizeTimer)
    resizeTimer = setTimeout(this.initAnimation, 50)
  }

  initAnimation = () => {
    const firstHelper = getPosition(document.getElementById('animation-helper-1'))
    const secondHelper = getPosition(document.getElementById('animation-helper-2'))
    const thirdHelper = getPosition(document.getElementById('animation-helper-3'))

    this.setState({
      isLoading: false,
      positions: {
        firstHelper,
        secondHelper,
        thirdHelper,
      },
    })
  }

  render() {
    const {
      isLoading,
      positions,
    } = this.state

    if (isLoading)
      return false

    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const preAnimation = {
       end: {
        css: {
          clip: 'rect(600px, auto, auto, auto)',
          right: `${vw - positions.secondHelper.x - positions.secondHelper.width * 0.45 - positions.secondHelper.width * 0.4 * 0.5}px`,
          rotation: '15deg',
          top: `calc(${positions.secondHelper.y}px - 1vw)`,
          width: `${positions.secondHelper.width * 0.25}px`,
        },
        ease: 'Strong.easeOut',
        onComplete: () => {
          document.getElementsByClassName('first-animation-elements')[0].style.visibility = 'hidden'
          document.getElementsByClassName('first-animation-elements')[1].style.visibility = 'hidden'

          document.getElementsByClassName('second-animation-elements')[0].style.visibility = 'visible'
          document.getElementsByClassName('second-animation-elements')[1].style.visibility = 'visible'
        },
      },
      start: {
        css: {
          clip: 'rect(auto, auto, auto, auto)',
          overflow: 'hidden',
          right: '7%',
          rotation: -8,
          top: `calc(${positions.firstHelper.y}px - 8vw)`,
          width: '11%',
        },
        ease: 'Strong.easeOut',
      },
    }
    const firstAnimation = {
      end: {
        css: {
          right: `${vw - positions.secondHelper.x - positions.secondHelper.width * 0.45 - positions.secondHelper.width * 0.4 * 0.5}px`,
          rotation: '15deg',
          top: `calc(${positions.secondHelper.y}px - 1vw)`,
          width: `${positions.secondHelper.width * 0.25}px`,
        },
        ease: 'Strong.easeOut',
        onComplete: () => {
          document.getElementsByClassName('first-animation-elements')[0].style.visibility = 'hidden'
          document.getElementsByClassName('first-animation-elements')[1].style.visibility = 'hidden'

          document.getElementsByClassName('second-animation-elements')[0].style.visibility = 'visible'
          document.getElementsByClassName('second-animation-elements')[1].style.visibility = 'visible'
        },
      },
      start: {
        css: {
          right: '7%',
          rotation: -8,
          top: `calc(${positions.firstHelper.y}px - 8vw)`,
          width: '11%',
        },
        ease: 'Strong.easeOut',
      },
    }

    const secondAnimation = {
      end: {
        css: {
          right: `${vw - positions.thirdHelper.x - positions.thirdHelper.width * 0.5 - positions.thirdHelper.width * 0.45 * 0.29}px`,
          rotation: '60deg',
          top: `${positions.thirdHelper.y * 0.9}px`,
          width: `${positions.thirdHelper.width * 0.34}px`,
        },
        ease: 'Strong.easeOut',
        onReverseComplete: () => {
          document.getElementsByClassName('first-animation-elements')[0].style.visibility = 'visible'
          document.getElementsByClassName('first-animation-elements')[1].style.visibility = 'visible'

          document.getElementsByClassName('second-animation-elements')[0].style.visibility = 'hidden'
          document.getElementsByClassName('second-animation-elements')[1].style.visibility = 'hidden'
        },
        onStart: () => {
          document.getElementsByClassName('first-animation-elements')[0].style.visibility = 'hidden'
          document.getElementsByClassName('first-animation-elements')[1].style.visibility = 'hidden'

          document.getElementsByClassName('second-animation-elements')[0].style.visibility = 'visible'
          document.getElementsByClassName('second-animation-elements')[1].style.visibility = 'visible'
        },
      },
      start: {
        css: {
          right: `${vw - positions.secondHelper.x - positions.secondHelper.width * 0.45 - positions.secondHelper.width * 0.4 * 0.5}px`,
          rotation: '15deg',
          top: `calc(${positions.secondHelper.y}px - 1vw)`,
          width: `${positions.secondHelper.width * 0.25}px`,
        },
        ease: 'Strong.easeOut',
      },
    }

    const secondAnimationTop = {
      css: {
        ...secondAnimation.end.css,
        rotation: '15deg',
        right: `${vw - positions.secondHelper.x - positions.secondHelper.width * 0.5 - positions.secondHelper.width * 0.4 * 0.55}px`,
        top: `${positions.thirdHelper.y - positions.thirdHelper.height * 0.02}px`,
      },
      ease: 'Strong.easeOut',
    }

    const waterAnimation = {
      end: {
        css: {
          opacity: 1,
          right: `${vw - positions.thirdHelper.x - positions.thirdHelper.width * 0.97}px`,
          rotation: 20,
          top: `${positions.thirdHelper.y}px`,
          width: `${positions.thirdHelper.width}px`,
        },
        ease: 'Strong.easeOut',
        onReverseComplete: () => {
          document.getElementsByClassName('second-animation-elements')[2].style.visibility = 'hidden'
        },
        onStart: () => {
          document.getElementsByClassName('second-animation-elements')[2].style.visibility = 'visible'
        },
      },
      start: {
        css: {
          opacity: 0,
          right: `${vw - positions.thirdHelper.x - positions.thirdHelper.width * 0.97}px`,
          rotation: 0,
          top: `${positions.thirdHelper.y * 0.75}px`,
          width: `${positions.secondHelper.width}px`,
        },
        ease: 'Strong.easeOut',
      },
    }

    return (
      <div className="animation-controller">
        <Controller>
          <Scene duration={1300} triggerHook="onLeave" triggerElement={null}>
            {(progress) => (
              <Timeline totalProgress={progress}>
                <Tween
                  from={preAnimation.start}
                  paused
                  to={preAnimation.end}
                  totalProgress={progress}
                >
                  <div className="animation-element first-animation-elements preAnimation">
                    <img className="fitImage" src={heinzFull} />
                  </div>
                </Tween>
              </Timeline>
            )}
          </Scene>

        </Controller>
        <Controller>
          <Scene duration={1300} triggerElement={null}>
            {(progress) => (
              <Tween
                from={firstAnimation.start}
                paused
                to={firstAnimation.end}
                totalProgress={progress}
              >
                <img className="animation-element first-animation-elements" src={heinzTop} />
              </Tween>
            )}
          </Scene>

          <Scene duration={1300} triggerElement={null}>
            {(progress) => (
              <Tween
                from={firstAnimation.start}
                paused
                to={firstAnimation.end}
                totalProgress={progress}
              >
                <img className="animation-element first-animation-elements" src={heinzBottom} />
              </Tween>
            )}
          </Scene>

        </Controller>
        <Controller>
          <Scene duration={1000} offset={positions.secondHelper.y} triggerElement={null}>
            {(progress) => (
              <Tween
                from={secondAnimation.start}
                paused
                to={secondAnimationTop}
                totalProgress={progress}
              >
                <img className="animation-element second-animation-elements" src={heinzTop} />
              </Tween>
            )}
          </Scene>

          <Scene duration={1000} offset={positions.secondHelper.y} triggerElement={null}>
            {(progress) => (
              <Tween
                from={secondAnimation.start}
                paused
                to={secondAnimation.end}
                totalProgress={progress}
              >
                <img className="animation-element second-animation-elements" src={heinzBottom} />
              </Tween>
            )}
          </Scene>


          <Scene duration={1000} offset={positions.secondHelper.y} triggerElement={null}>
            {(progress) => (
              <Tween
                from={waterAnimation.start}
                paused
                to={waterAnimation.end}
                totalProgress={progress}
              >
                <img className="animation-element second-animation-elements animated-image-helper" src={homeImages.haagenDazsEcolabWater} />
              </Tween>
            )}
          </Scene>
        </Controller>
      </div>
    )
  }
}

export default Animation


import careers from './careers.svg'
import customer from './customer.png'
import invest from './invest.svg'
import partner from './partner.svg'

const joinImages = {
  careers,
  customer,
  invest,
  partner,
}

export default joinImages

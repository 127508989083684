import React from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Card } from 'react-bootstrap'
import { FadeIn, SEO, Separator } from '../../components'
import { loopEcosystem } from '../../images'
import joinImages from '../../images/join'

import './index.css'

const JoinPage = () => {
  const joinUsCards = [
    {
      image: joinImages.partner,
      text: ['partnerWithLoop'],
      title: 'partner',
    },
    {
      image: joinImages.invest,
      text: ['toLearnMoreAbout'],
      title: 'invest',
    },
    {
      image: joinImages.careers,
      text: ['bePartOfAPassionate'],
      title: 'careers',
    },
    {
      image: joinImages.customer,
      text: ['noneOfTheseContactOur'],
      title: 'customerCare',
    },
  ]

  return (
    <>
      <SEO title="Join" />

      <div className="main-section-background">
        <div className="main-section first-page-section">
          <div className="container-lg main-section-content">
            <div className="main-section-part padded-text-content">
              <FadeIn horizontal>
                <div className="main-section-title primary-title">
                  <Trans>buildingAGlobalCircularEconomy</Trans>
                </div>
              </FadeIn>
              <FadeIn delay={600}>
                <div className="main-section-text primary-text">
                  <Trans>loopIsBuilding</Trans>
                </div>
              </FadeIn>
            </div>
            <div className="main-section-part second-section-part">
              <FadeIn delay={1000}>
                <img
                  alt="Conference"
                  className="section-image padded-image-content"
                  src={loopEcosystem}
                />
              </FadeIn>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg">
        <Separator />
      </div>
      <div className="container-lg custom-container">
        <div className="card-block">
          <div className="main-section-title"><Trans>joinUs</Trans></div>
          <div className="join-card-container">
            <JoinUsCard card={joinUsCards[0]} delay={600}>
              <Trans
                components={{
                  button: <Button className="main-button join-us-button" href="mailto:partnerships@exploreloop.com" variant="primary" />,
                }}
              >
                reachOutToPartnership
              </Trans>
            </JoinUsCard>

            <JoinUsCard card={joinUsCards[1]} delay={1200}>
              <Trans
                components={{
                  button: <Button className="main-button join-us-button" href="mailto:invest@exploreloop.com" variant="primary" />,
                }}
              >
                reachOutToInvest
              </Trans>
            </JoinUsCard>

            <JoinUsCard card={joinUsCards[2]} delay={1800}>
              <Trans>browseCareer</Trans>
              <br />
              <div className="location-separator">&nbsp;</div>
              <Trans
                components={{
                  button: <Button className="main-button join-us-button" href="https://recruiting.paylocity.com/recruiting/jobs/All/0e6f52c3-18b3-4884-ab4d-e1d9947cbe07/TerraCycle" rel="noopener" target="_blank" variant="primary" />,
                }}
              >
                northAmericaAustralia
              </Trans>

              <div className="location-separator">&nbsp;</div>

              <Trans
                components={{
                  button: <Button className="main-button join-us-button" href="https://terracycle.breezy.hr/" rel="noopener" target="_blank" variant="primary" />,
                }}
              >
                europe
              </Trans>

              <div className="location-separator">&nbsp;</div>

              <Trans
                components={{
                  button: <Button className="main-button join-us-button" href="https://www.terracycle.com/ja-JP/pages/careersjp" rel="noopener" target="_blank" variant="primary" />,
                }}
              >
                japan
              </Trans>
            </JoinUsCard>

            <JoinUsCard card={joinUsCards[3]} delay={2200}>
              <div className="location-separator">&nbsp;</div>

              <div className="location-separator">&nbsp;</div>
            </JoinUsCard>
          </div>
        </div>
      </div>
    </>
  )
}

const JoinUsCard = ({ card, children, delay }) => {
  const { t } = useTranslation()

  return (
    <FadeIn delay={delay}>
      <Card className="join-us-card">
        <Card.Img
          alt={card.title}
          src={card.image}
          variant="top"
        />
        <Card.Body>
          <Card.Title>
            <Trans>{card.title}</Trans>
          </Card.Title>

          {card.text.map(text => (
            <Card.Text
              key={text.slice(0, 15)}
            >
              <Trans
                components={{
                  button: <Button className="main-button join-us-button" href={`mailto:${t('global.email')}`} variant="primary" />,
                }}
              >
                {text}
              </Trans>
            </Card.Text>
          ))}
          {children}
        </Card.Body>
      </Card>
    </FadeIn>
  )
}

JoinUsCard.propTypes = {
  card: PropTypes.object,
  children: PropTypes.node,
  delay: PropTypes.number,
}

export default JoinPage

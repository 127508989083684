import React from 'react'
import { topArrow } from '../../images'

const ScrollToTop = () => {
  let mybutton

  window.onscroll = function () {
    mybutton = document.getElementById('btn-back-to-top')
    scrollFunction(mybutton)
  }

  function scrollFunction(mybutton) {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      mybutton.style.display = 'block'
    } else {
      mybutton.style.display = 'none'
    }
  }

  const backToTop = () => {
    window.scroll({ behavior: 'smooth', left: 0, top: 0 })
  }

  return (
    <div className="top-arrow-container" id="btn-back-to-top">
      <img alt="back-to-top" className="top-arrow" onClick={backToTop} src={topArrow} />
    </div>
  )
}

export default ScrollToTop

import React from 'react'

export const AeonPin = () => (
  <g
    className="retailer-pin"
    id="Group_1775_1_"
    transform="translate(356.155 50)"
  >
    <g id="Group_1771_1_">
      <linearGradient
        gradientTransform="matrix(65.0985 0 0 -55.3762 132773.375 14304.583)"
        gradientUnits="userSpaceOnUse"
        id="Path_7753-3_3_"
        x1="-2055.417"
        x2="-2054.8403"
        y1="261.9241"
        y2="262.8228"
      >
        <stop offset="0" style={{ stopColor: '#000000' }} />
        <stop offset="1" style={{ stopColor: '#F0F0F0', stopOpacity:0 }} />
      </linearGradient>
      <path
        className="st10"
        d="M-993.8-251.3c-15.6,0-33.2,7.5-39.4,16.7c-11.1,16.7,3.8,36.7,3.8,36.7
      s41.6-20,52.7-36.7C-970.5-243.9-978.2-251.3-993.8-251.3z"
        id="Path_7753-3_1_"
      />
      <g id="Group_762-3_1_" transform="translate(0 0)">
        <path
          className="st1"
          d="M-1029.4-295.4c-22.3,0-41,20.5-41,37.2c0,30.4,41,60,41,60s39.9-29.6,39.9-60
        C-989.5-274.9-1007.1-295.4-1029.4-295.4z"
          id="Path_7754-3_1_"
        />
      </g>
    </g>
    <path
      className="st11"
      d="M-1037.9-255c0-0.6-0.1-1.2-0.1-2c0.1-5.9,5-10.6,10.9-10.4c5.9,0.1,10.6,5,10.4,10.9
    c-0.1,5.9-5,10.6-10.9,10.4c-4.3-0.1-8.1-2.7-9.7-6.7h-2.6c-2.6,0-5.1-0.1-7.7-0.5l0,0v4.8h10.1v2.1h-12.3v-7.3
    c-2.9-0.6-4.7-1.7-4.7-2.8c0-1.1,1.4-2.3,4.7-2.9v-6.4l-13.6,19.4h-2.8l14.5-20.9h14.1v2.3h-10.1v5.3c1.5-0.1,3.4-0.3,5.5-0.3
    c-1.9,0-3.7,0.2-5.5,0.6v4c2.6,0.4,5.3,0.6,7.9,0.6h1.7L-1037.9-255L-1037.9-255z M-999-267.4v20.9h-2.3l-11.9-17v17h-2.2v-20.9
    h2.2l11.9,17.1v-17.1L-999-267.4z M-1034.9-253c5.5-0.6,9.5-1.8,9.5-3.5c0-1.4-1.8-2.6-7.8-3.2c3.5,0.5,6,1.6,6,2.5
    c0,1.1-3.5,2-8.4,2.3c-0.1-0.7-0.2-1.3-0.1-2c0-4.7,3.8-8.5,8.5-8.5c4.7,0,8.5,3.8,8.5,8.5c0,0,0,0,0,0c0,4.8-3.8,8.7-8.6,8.7
    C-1030.6-248.3-1033.5-250.2-1034.9-253L-1034.9-253z M-1049.7-255.9c-1.5-0.3-2.4-0.9-2.4-1.4s0.9-1.1,2.4-1.6V-255.9
    L-1049.7-255.9z"
      id="aeon_1_"
    />
  </g>
)

import React from 'react'
import Markdown from 'react-markdown'
import { useLoaderData } from 'react-router-dom'
import { SEO } from '../../components'
import './success.css'

const SuccessPage = () => {
  const { data: { data }} = useLoaderData()

  return (
    <>
      <SEO title="Waiting List - Form Submission - Success" />

      <div className="form-page success-page-content-wrapper">
        <img
          alt={data.attributes.Image.data.attributes.alternativeText}
          className="success-image"
          src={data.attributes.Image.data.attributes.url}
        />

        <h1>{data.attributes.Title}</h1>

        <div className="success-paragraphs">
          <Markdown>{data.attributes.Content}</Markdown>
        </div>
      </div>
    </>
  )
}

export default SuccessPage

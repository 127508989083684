import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { useLoaderData } from 'react-router-dom'
import minBy from 'lodash.minby'
import isEmpty from 'lodash.isempty'
import { Trans } from 'react-i18next'
import { Button, Form } from 'react-bootstrap'
import { FadeIn, Link, SEO } from '../../components'

import './index.css'

dayjs.extend(isBetween)

const PressPage = () => {
  const { data } = useLoaderData()

  const [filteredData, setFilteredData] = useState([])

  const [newsDates, setNewsDates] = useState([])
  const pressFeedRef = useRef(null)
  const keywordRef = useRef(null)
  const dateRef = useRef(null)

  const scrollToResults = () => {
    const refPosition = pressFeedRef.current?.offsetTop

    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: refPosition - 20,
    })
  }

  const onClickSearch = () => {
    if (isEmpty(data.data)) return null

    const selectedDate = dateRef.current.value

    let filteredFeed = []

    if (selectedDate || keywordRef.current.value) {
      filteredFeed = data.data.filter(article => {
        return (
          (selectedDate ? dayjs(article.attributes.migrationDate || article.attributes.createdAt).isSame(selectedDate, 'month') : true) &&
          ((article.attributes.Content.toLowerCase().includes(keywordRef.current.value)) || (article.attributes.Title.toLowerCase().includes(keywordRef.current.value)))
        )
      })

      setFilteredData(filteredFeed)
      scrollToResults()
    } else {
      setFilteredData(data.data)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onClickSearch()
    }
  }

  useEffect(() => {
    if (isEmpty(data.data)) return null

    const getSelectorDates = () => {
      const oldestArticle = minBy(data.data, (n) => n.attributes.migrationDate ? n.attributes.migrationDate : n.attributes.createdAt)
      const minDate = dayjs(oldestArticle.attributes.migrationDate || oldestArticle.attributes.createdAt)
      const minDifferent = dayjs().diff(minDate, 'month')

      const dates = Array.from(Array(minDifferent + 1).keys()).map(index =>
        minDate.set('month', (minDate.get('month') + index)),
      )

      setNewsDates(dates.reverse())
    }
    setFilteredData(data.data)
    getSelectorDates()
  }, [data.data])

  return (
    <>
      <SEO title="Press" />
      <div className="container-lg">
        <div className="search-page-content">
          <div className="press-header">
            <FadeIn
              animateClass="animate__animated"
              animation="fadeIn"
              offset={-100}
            >
              <div className="main-section-title"><Trans>loopInTheNews</Trans></div>

              <div className="main-section-text primary-text">
                <Trans
                  components={{
                    button: <Link className="press-page-link" externalLink="https://sites.google.com/terracycle.com/loop-press-media-room/loop-media-room">Media Room</Link>,
                  }}
                >
                  ifYouAreAMember
                </Trans>
              </div>
            </FadeIn>
          </div>

          <div className="search-section">
            <Form.Group
              className="press-search-field"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label><Trans>keyword</Trans></Form.Label>
              <Form.Control
                className="press-form-control"
                onKeyDown={handleKeyPress}
                ref={keywordRef}
                type="text"
              />
            </Form.Group>

            <Form.Group
              className="press-search-field"
              controlId="exampleForm.ControlSelect1"
            >
              <Form.Label><Trans>date</Trans></Form.Label>
              <Form.Control
                as="select"
                className="press-form-control"
                onKeyDown={handleKeyPress}
                ref={dateRef}
              >
                <option value=""><Trans>date</Trans></option>
                {(newsDates).map(date => (
                  <option
                    key={date}
                    value={dayjs(date).format('YYYY-MM-DD')}
                  >
                    {dayjs(date).format('MMMM YYYY')}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Button
              className="main-button press-search-button"
              onClick={onClickSearch}
              variant="primary"
            >
              <Trans>search</Trans>
            </Button>
          </div>

          <div className="news-section" ref={pressFeedRef}>
            <div className="main-section-subtitle"><Trans>newsArticles</Trans></div>

            {filteredData.length === 0 && (
              <div className="press-highlighted-item-content no-news">
                -
              </div>
            )}

            {filteredData?.length > 0 && (
              <>
                <div className="highlighted-news">
                  {filteredData?.slice(0, 3).map((item, index) => (
                    <ArticleItem className="press-highlighted-item" delay={600 * index} item={item} key={item.id} />
                  ))}
                </div>

                <div className="additional-items">
                  {filteredData?.slice(3).map((item, index) => (
                    <ArticleItem className="press-additional-item" delay={600 * (index % 2)} item={item} key={item.id} />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const ArticleItem = ({ className, delay, item }) => (
  <FadeIn delay={delay}>
    <div className={className}>
      <Link
        className="item-title press-page-link"
        externalLink={item.attributes.Link || ''}
      >
        {item.attributes.Title.replace(/&#(\d+);/g, (_, n) => String.fromCharCode(n))}
      </Link>

      <div className="press-highlighted-item-content">
        {item.attributes.Content.replace(/&#(\d+);/g, (_, n) => String.fromCharCode(n))}
        <span>
        ...&nbsp;
          <Link
            className="continue-reading press-page-link"
            externalLink={item.attributes.Link || ''}
          >
            <Trans>continueReading</Trans>
          </Link>
        </span>

        <div className="press-item-date">
          {item.attributes.Source ? `${item.attributes.Source } - ` : ''}{dayjs(item.attributes.migrationDate ||item.attributes.createdAt).format('MMM DD, YYYY')} {item.attributes.Country?.Countries ? ` - ${item.attributes.Country?.Countries.join(', ')}` : ''}
        </div>
      </div>
    </div>
  </FadeIn>
)

ArticleItem.propTypes = {
  className: PropTypes.string,
  delay: PropTypes.number,
  item: PropTypes.object,
}

export default PressPage

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Waypoint } from 'react-waypoint'
import { useSpring, animated } from 'react-spring'

const FadeIn = ({ children, delay = 500, horizontal = false }) => {
  const [inView, setInview] = useState(false)

  const transition = useSpring({
    delay,
    to: {
      opacity: !inView ? 0 : 1,
      ...{[(horizontal) ? 'x' : 'y']: !inView ? 68 : 0 },
    },
  })

  return (
    <Waypoint onEnter={() => setInview(true)}>
      <animated.div style={transition}>
        {children}
      </animated.div>
    </Waypoint>
  )
}

FadeIn.propTypes = {
  children: PropTypes.node,
  delay: PropTypes.number,
  horizontal: PropTypes.bool,
}
export default FadeIn

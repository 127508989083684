import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

const MobileAppDoNotSellPage = () => {
  const data = useOutletContext()

  return (
    <div className="container do-not-sell-page font-weight-bold">
      <Helmet
        metaArray={[
          { content: data.SeoDescription , name: 'description' },
          { content: data.SeoDescription , property: 'og:description' },
          { content: data.SeoTitle , property: 'og:title' },
        ]}
        title={data.SeoTitle}
      />

      <Markdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>{data.DoNotSell}</Markdown>
    </div>
  )
}

export default MobileAppDoNotSellPage

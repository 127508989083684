import React from 'react'

export const CarrefourPin = () => (
  <g
    className="retailer-pin"
    id="Group_1778_2_"
    transform="translate(-21 278.462)"
  >
    <g id="Group_1773_2_" transform="translate(0 0)">
      <linearGradient
        gradientTransform="matrix(65.0985 0 0 -55.3762 55214.7695 1653.2206)"
        gradientUnits="userSpaceOnUse"
        id="Path_7753-6_3_"
        x1="-861.9944"
        x2="-861.3533"
        y1="30.2501"
        y2="31.2494"
      >
        <stop offset="0" style={{ stopColor: '#000000' }} />
        <stop offset="1" style={{ stopColor: '#F0F0F0', stopOpacity: 0 }} />
      </linearGradient>
      <path
        className="st14"
        d="M-858.2-79.2c-17.4,0-37,8.3-43.8,18.6c-12.4,18.5,4.2,40.8,4.2,40.8
        s46.3-22.3,58.6-40.8C-832.3-70.9-840.8-79.2-858.2-79.2z"
        id="Path_7753-6_2_"
      />
      <g id="Group_762-6_2_" transform="translate(0 0)">
        <path
          className="st1"
          d="M-897.8-128.2c-24.8,0-45.6,22.8-45.6,41.4c0,33.8,45.6,66.7,45.6,66.7
          s44.3-32.9,44.3-66.7C-853.5-105.4-873-128.2-897.8-128.2z"
          id="Path_7754-6_2_"
        />
      </g>
    </g>
    <g id="carrefour_2_" transform="translate(9.791 21.007)">
      <path
        className="st15"
        d="M-923-125.2c0.1-0.1,0.2-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.3c0,0,0,0,0,0
        c0,0.1-0.1,0.3-0.2,0.3c-4.8,5.8-7.4,13-7.4,20.5c0,8.8,3.1,15,7.4,20.4c0.1,0.1,0.2,0.2,0.2,0.3c0,0.2-0.1,0.3-0.3,0.3
        c0,0,0,0,0,0c-0.1,0-0.2-0.1-0.3-0.2l-18.2-18c-1.7-1.3-2-3.7-0.7-5.4c0.2-0.3,0.4-0.5,0.7-0.7L-923-125.2"
        id="Path_7691_2_"
      />
      <path
        className="st16"
        d="M-909.1-126.7c-7.1,0-9.9,10.3-9.9,22.6s2.8,22.4,9.9,22.4c4.3,0,7.9-2.6,7.9-4.7
        c0-0.5-0.2-1-0.6-1.3c-1.7-1.5-2.7-3.5-2.8-5.8c0-3.1,2.4-5.7,5.5-5.9c3.6,0,5.7,2.9,5.7,6.7c-0.1,3.2-1.2,6.3-3.1,8.9
        c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.3-0.1,0.3-0.2l18.2-18c1.7-1.3,2-3.7,0.7-5.4
        c-0.2-0.3-0.4-0.5-0.7-0.7l-18.2-18c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2,0-0.3,0.2-0.3,0.3c0,0.1,0,0.2,0.1,0.3
        c1.9,2.6,3,5.7,3.1,8.9c0,3.8-2.1,6.7-5.7,6.7c-3.1-0.2-5.5-2.8-5.5-5.9c0.1-2.2,1.1-4.3,2.8-5.8c0.4-0.3,0.5-0.8,0.6-1.3
        C-901.2-124.1-904.8-126.7-909.1-126.7z"
        id="Path_7692_2_"
      />
    </g>
  </g>
)

import React from 'react'

export const AeonPin = () => (
  <g
    className="retailer-pin"
    id="Group_1775_2_"
    transform="translate(356.155 50)"
  >
    <g id="Group_1771_2_">
      <linearGradient
        gradientTransform="matrix(65.0985 0 0 -55.3762 79767 14304.583)"
        gradientUnits="userSpaceOnUse"
        id="Path_7753-3_3_"
        x1="-1241.2614"
        x2="-1240.6201"
        y1="259.1579"
        y2="260.1572"
      >
        <stop offset="0" style={{ stopColor: '#000000' }} />
        <stop offset="1" style={{ stopColor: '#F0F0F0', stopOpacity: 0 }} />
      </linearGradient>
      <path
        className="st10"
        d="M-995.7-103.9c-17.4,0-37,8.3-43.8,18.6c-12.4,18.5,4.2,40.8,4.2,40.8
        s46.3-22.3,58.6-40.8C-969.8-95.6-978.3-103.9-995.7-103.9z"
        id="Path_7753-3_2_"
      />
      <g id="Group_762-3_2_" transform="translate(0 0)">
        <path
          className="st1"
          d="M-1035.3-152.9c-24.8,0-45.6,22.8-45.6,41.4c0,33.8,45.6,66.7,45.6,66.7
          s44.3-32.9,44.3-66.7C-990.9-130.1-1010.4-152.9-1035.3-152.9z"
          id="Path_7754-3_2_"
        />
      </g>
    </g>
    <path
      className="st11"
      d="M-1044.8-108c0-0.7-0.2-1.4-0.2-2.2c0.1-6.5,5.6-11.7,12.1-11.6s11.7,5.6,11.6,12.1
      c-0.1,6.5-5.6,11.7-12.1,11.6c-4.8-0.1-9-3.1-10.8-7.5h-2.9c-2.8,0-5.7-0.1-8.5-0.5l0,0v5.3h11.2v2.4h-13.6v-8.2
      c-3.2-0.7-5.3-1.8-5.3-3.1c0-1.2,1.5-2.6,5.3-3.2v-7.1l-15.1,21.6h-3.1l16.1-23.3h15.7v2.6h-11.2v5.9c1.7-0.2,3.7-0.3,6.1-0.3
      c-2.1,0-4.1,0.2-6.1,0.7v4.4c2.9,0.4,5.9,0.6,8.8,0.7h1.9V-108L-1044.8-108z M-1001.5-121.8v23.3h-2.6l-13.3-18.9v18.9h-2.4
      v-23.3h2.4l13.3,19v-19L-1001.5-121.8z M-1041.3-105.8c6.1-0.7,10.5-2,10.5-3.9c0-1.5-2-2.9-8.7-3.6c3.9,0.5,6.6,1.7,6.6,2.7
      c0,1.2-3.9,2.2-9.3,2.6c-0.1-0.7-0.2-1.5-0.2-2.2c0-5.2,4.2-9.4,9.4-9.5c5.2,0,9.4,4.2,9.5,9.4c0,0,0,0,0,0
      c0,5.3-4.2,9.6-9.5,9.7C-1036.6-100.6-1039.8-102.6-1041.3-105.8L-1041.3-105.8z M-1057.9-109c-1.7-0.4-2.7-1-2.7-1.5
      c0-0.5,1-1.2,2.7-1.7V-109L-1057.9-109z"
      id="aeon_2_"
    />
  </g>
)

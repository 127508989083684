import React from 'react'
import PropTypes from 'prop-types'
import { useLoaderData } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { Card } from 'react-bootstrap'
import { FadeIn, SEO, PartnersRetailerMap, Separator } from '../../components'

import './index.css'

const PartnersPage = () => {
  const { data: { data } } = useLoaderData()

  const partnersData = data.attributes.PartnerBlocks
    .map(partnerBlock => ({
      andManyMore: partnerBlock.AndManyMore,
      images: partnerBlock.Logos,
      text: partnerBlock.Text,
      title: partnerBlock.Title,
    }),
    ).flat() || null

  return (
    <div style={{ marginBottom: 100 }}>
      <SEO title="Partners" />

      <div className="main-section-background">
        <div className="main-section first-page-section">
          <div className="container-lg main-section-content">
            <div className="main-section-part padded-text-content">
              <FadeIn horizontal>
                <div className="main-section-title primary-title">
                  <Trans>aGlobalReuseEcosystem</Trans>
                </div>
              </FadeIn>
              <FadeIn delay={600}>
                <div className="main-section-text primary-text">
                  <Trans>loopIsAGlobalPlatform</Trans>
                </div>
              </FadeIn>
            </div>
            <div className="main-section-part second-section-part">
              <FadeIn delay={1000} horizontal>
                <PartnersRetailerMap />
              </FadeIn>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg">
        <Separator />
      </div>
      <div className="container-lg">
        <div className="card-block">
          <FadeIn horizontal>
            <h3 className="main-section-title"> <Trans>partnerships</Trans></h3>
          </FadeIn>

          {partnersData.map(block => (
            <PartnerBlock block={block} key={block.title} />
          ))}
        </div>
      </div>
    </div>
  )
}

const PartnerBlock = ({ block }) => (
  <FadeIn>
    <Card className="partner-card">
      <Card.Body>
        <Card.Title>
          <Trans>{block.title}</Trans>
        </Card.Title>
        <Card.Text>
          <Trans>{block.text}</Trans>
        </Card.Text>
        <div className="partners-logo-container">
          {block.images.data.map((image) => (
            <img
              alt={image.attributes.alternativeText || ''}
              className="logo-list-item"
              key={image.id}
              src={image.attributes.url}
            />
          ))}
        </div>
        {block.andManyMore && <div className="and-many-more"><Trans>andManyMore</Trans></div>}
      </Card.Body>
    </Card>
  </FadeIn>
)

PartnerBlock.propTypes = {
  block: PropTypes.object,
}

export default PartnersPage

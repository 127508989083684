import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash.isempty'
import { useParams } from 'react-router-dom'
import { Container, Spinner } from 'react-bootstrap'
import { Error404Page } from '../'
import {
  BrandPageNavigation,
  Link,
  Map,
  RetailerHeader,
  Section,
  SEO,
} from '../../components'

import strapiInstance from '../../api'

import './index.css'

export default function RetailerPage() {
  const { brandUrl } = useParams()
  const [retailerData, setrRetailerdata] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const { i18n } = useTranslation()
  const { language } = i18n

  useEffect(() => {
    const getBrandPage = async () => {
      const { data } = await strapiInstance(`/brand-pages?populate=deep&locale=${language}&filters[$and][0][Url][$eq]=${brandUrl}`)

      if (!isEmpty(data.data)) {
        setrRetailerdata(data.data[0])
      }

      setIsLoading(false)
    }

    getBrandPage()

    window.scroll({ behavior: 'smooth', left: 0, top: 0 })
  }, [brandUrl, language])

  if (isLoading)
    return (
      <div className="loading-layout">
        <Spinner animation="grow" />
      </div>
    )

  if (isEmpty(retailerData))
    return <Error404Page />

  return (
    <>
      <SEO title="Retailers" />

      <RetailerHeader data={retailerData} />

      <BrandPageNavigation data={retailerData.attributes.BrandPageNavigation} />

      <div className="container-lg retailers-content-container">
        {retailerData.attributes?.LocationInfo && (
          <>
            <div className="retailer-title-section">
              <div id="en-magasin">{retailerData.attributes?.LocationInfo?.title}</div>
              <p>{retailerData.attributes?.LocationInfo?.subtitle}</p>
            </div>
            <Map markers={retailerData.attributes?.LocationInfo?.locations} />
          </>
        )}

        {React.Children.toArray(
          (retailerData.attributes.PageContent ?? []).map(section => <Section data={section} />),
        )}

        {retailerData?.attributes.externalUrlWithButtonText &&
          <Container className="d-flex justify-content-center">
            <Link className="main-button mt-5 mb-5 button-link" externalLink={retailerData?.attributes.externalUrlWithButtonText.url}>
              {retailerData?.attributes.externalUrlWithButtonText.text}
            </Link>
          </Container>
        }

        {retailerData?.attributes.BrandsImage &&
          <>
            <div className="available-brands-title">{retailerData?.attributes.AvailableBrandsTitle || 'Available Brands'}</div>
            <div className="available-brand-logos">
              <img
                alt=""
                className="desktop-image"
                src={retailerData?.attributes.BrandsImage.DesktopImage?.data?.attributes?.url}
                style={{ height: 'auto', width: '100%' }}
              />
              <img
                alt=""
                className="mobile-image"
                src={retailerData?.attributes.BrandsImage.MobileImage?.data?.attributes?.url}
                style={{ height: 'auto', width: '100%' }}
              />
            </div>
          </>
        }
      </div>
    </>
  )
}


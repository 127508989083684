import React from 'react'
import PropTypes from 'prop-types'
import { chevronLeft, chevronRight } from '../../images'
import './index.css'

const Arrow = ({ className = '', direction, hasBorder = false, onClick }) => (
  <div className={`slider-arrow ${hasBorder ? 'arrow-with-border' : ''} ${className}`} onClick={onClick}>
    <img alt={`${direction}-arrow`} className={`${direction}-arrow`} src={(direction === 'left') ? chevronLeft : chevronRight} />
  </div>
)

Arrow.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.string.isRequired,
  hasBorder: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Arrow

import bannerImageMobile from './banner-image-mobile.png'
import fastCompany from './fast-company.svg'
import haagenDazs from './haagen-dazs.png'
import heinz from './heinz.png'
import haagenDazsEcolab from './haagen-dazs-ecolab.png'
import haagenDazsEcolabMobile from './haagen-dazs-ecolab-mobile.png'
import haagenDazsEcolabWater from './haagen-dazs-ecolab-water.png'
import heinzProductBg from './product_bg.png'
import heinzProductBgMobile from './heinz_bg_prod.png'
import heinzTop from './heinz-cap.png'
import heinzBottom from './heinz-bottle.png'
import purposeImage from './home-purpose.png'
import retailerMapBackground from './retailer-map-background.png'

const homeImages = {
  bannerImageMobile,
  fastCompany,
  haagenDazs,
  haagenDazsEcolab,
  haagenDazsEcolabMobile,
  haagenDazsEcolabWater,
  heinz,
  heinzBottom,
  heinzProductBg,
  heinzProductBgMobile,
  heinzTop,
  purposeImage,
  retailerMapBackground,
}

export default homeImages

import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'react-bootstrap'
import {
  ContentText,
  FullWidthImage,
  ImageWithTextOverlay,
  Link,
  Separator,
  TextWithImage,
  TitleWithSubtitle,
} from '../'

const Section = ({ data }) => {
  if (!data) return null

  switch (data.__component) {
  case 'global.separator':
    return (
      <div className="mt-5">
        <Separator />
      </div>
    )

  case 'global.title-with-subtitle':
    return <TitleWithSubtitle data={data} />

  case 'global.image-with-text-overlay':
    return <ImageWithTextOverlay data={data} />

  case 'global.full-width-image':
    return <FullWidthImage data={data} />

  case 'global.text-with-image':
    return <TextWithImage data={data} />

  case 'global.richtext-with-image':
    return <TextWithImage data={data} isRichText />

  case 'global.content-text':
    return <ContentText data={data} />

  case 'parts.label-with-link':
    return (
      <Container className="d-flex justify-content-center">
        <Link className="main-button mt-5 mb-5 button-link" externalLink={data.Url}>
          {data.Label}
        </Link>
      </Container>
    )
  default:
    return null
  }
}

Section.propTypes = {
  data: PropTypes.object,
}

export default Section

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import Recaptcha from 'react-google-recaptcha'
import Markdown from 'react-markdown'
import { useNavigate } from 'react-router-dom'
import countries from '../../constants/countries'
import { Panel } from '../'

let recaptchaInstance

const Form = (props) => {
  const navigate = useNavigate()

  const [state, setState] = useState({
    address: '',
    city: '',
    consenttext: false,
    country: '',
    email: '',
    error: null,
    errorMessage: null,
    isEmailFieldOK: true,
    isLoading: false,
    isThirdPartyAccepted: false,
    name: '',
    postalCode: '',
    reCaptchaVerification: null,
    showFieldsRequiredMessage: false,
    state: '',
  })
  const { captchaSiteKey, signUpForm } = props

  const handleInputChange = ({ target: { name, value } }) => {
    setState({ ...state, [name]: value })
  }

  const handleCheckboxChange = ({ target: { name, checked } }) => {
    setState({ ...state, [name]: checked })
  }

  const areAllRequiredFieldsFilled = () => {
    return !!state.name && !!state.email && !!state.consenttext && !!state.reCaptchaVerification
  }

  const handleFormSubmit = event => {
    event.preventDefault()
    setState({
      ...state,
      errorMessage: null,
      isLoading: true,
    })

    const requiredFieldsFilled = areAllRequiredFieldsFilled()

    if (!requiredFieldsFilled) {
      setState({
        ...state,
        error: {},
        errorMessage: signUpForm.Labels.formError,
        isLoading: false,
      })
      return null
    }

    const { backendUrl } = props
    const requestConfig = {
      crossdomain: true,
      data: {
        interested_user: {
          address: state.address,
          city: state.city,
          consenttext: state.consenttext,
          country: state.country,
          email: state.email,
          is_third_party_accepted: state.isThirdPartyAccepted,
          name: state.name,
          postalCode: state.postalCode,
          state: state.state,
        },
        recaptchaResponse: state.reCaptchaVerification,
      },
      method: 'post',
      url: backendUrl,
    }

    axios(requestConfig)
      .then(() => {
        recaptchaInstance.props.grecaptcha.reset()

        setState({
          address: '',
          city: '',
          consenttext: false,
          country: '',
          email: '',
          error: null,
          errorMessage: null,
          isEmailFieldOK: true,
          isLoading: false,
          isThirdPartyAccepted: false,
          name: '',
          postalCode: '',
          reCaptchaVerification: null,
          state: '',
        })

        navigate('/success')
      })
      .catch(({ response }) => {
        handleError(response.data.errors)
        setState({
          ...state,
          error: response.data.errors,
          isLoading: false,
        })
      })
  }

  const handleError = errorData => {
    recaptchaInstance.props.grecaptcha.reset()

    const isEmailInvalid = Object.keys(errorData).some(
      key => key === 'email' && errorData[key][0].error === 'invalid',
    )

    if (isEmailInvalid) {
      setState({
        ...state,
        errorMessage: signUpForm.Labels.invalidEmail,
        isEmailFieldOK: false,
        reCaptchaVerification: null,
      })

      return null
    }

    const isEmailNotUnique = Object.keys(errorData).some(
      key => key === 'email' && errorData[key][0].error === 'taken',
    )

    if (isEmailNotUnique) {
      setState({
        ...state,
        errorMessage: signUpForm.Labels.emailAlreadyTaken,
        isEmailFieldOK: false,
        reCaptchaVerification: null,
      })

      return null
    }

    setState({
      ...state,
      errorMessage: signUpForm.Labels.errorGeneric,
      isEmailFieldOK: true,
      reCaptchaVerification: null,
    })
  }

  const isFilled = fieldName => !!state[fieldName]

  const verifyCallback = response => {
    setState({ ...state, reCaptchaVerification: response })
  }

  return (
    <>
      <Panel
        anchorUrlName="reserve-your-spot-in-line"
        heading={signUpForm.Title}
        panelStyle={{
          backgroundColor: '#FFF',
          flexDirection: 'column',
          padding: '4rem 0 3.5rem 0',
          position: 'relative',
        }}
      >
        <div className="form-container">
          <div
            className="form-paragraphs-wrapper"
          >
            <Markdown>{signUpForm.Description}</Markdown>
          </div>

          <div className="form-wrapper">
            <form onSubmit={handleFormSubmit}>
              <div className="form-text-input-wrapper">
                <label
                  className={`form-input-label ${
                    !!state.error && !isFilled('name') ? 'form-error-message' : ''
                  }`}
                  htmlFor="name"
                >
                  {signUpForm.Labels.name}
                </label>
                <input
                  className={`form-text-input-field ${
                    !!state.error && !isFilled('name') ? 'error' : ''
                  }`}
                  id="name"
                  name="name"
                  onChange={handleInputChange}
                  type="text"
                  value={state.name}
                />
              </div>

              <div className="form-text-input-wrapper">
                <label
                  className={`form-input-label ${
                    !!state.error && (!isFilled('email') || !state.isEmailFieldOK)
                      ? 'form-error-message'
                      : ''
                  }`}
                  htmlFor="email"
                >
                  {signUpForm.Labels.email}
                </label>
                <input
                  className={`form-text-input-field ${
                    !!state.error && (!isFilled('email') || !state.isEmailFieldOK)
                      ? 'error'
                      : ''
                  }`}
                  id="email"
                  name="email"
                  onChange={handleInputChange}
                  type="text"
                  value={state.email}
                />
              </div>

              <div>
                <div className="form-text-input-wrapper">
                  <label className="form-input-label" htmlFor="country">
                    {signUpForm.Labels.country}
                  </label>

                  <select
                    className="form-select-field"
                    id="country"
                    name="country"
                    onBlur={handleInputChange}
                    onChange={handleInputChange}
                    value={state.country}
                  >
                    <option value=""></option>
                    {countries.map((country) => (
                      <option key={country.name} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-text-input-wrapper">
                  <label className="form-input-label" htmlFor="address">
                    {signUpForm.Labels.streetAddress}
                  </label>
                  <input
                    className="form-text-input-field"
                    id="address"
                    name="address"
                    onChange={handleInputChange}
                    type="text"
                    value={state.address}
                  />
                </div>

                <div className="form-text-input-wrapper">
                  <label className="form-input-label" htmlFor="city">
                    {signUpForm.Labels.city}
                  </label>
                  <input
                    className="form-text-input-field"
                    id="city"
                    name="city"
                    onChange={handleInputChange}
                    type="text"
                    value={state.city}
                  />
                </div>

                <div className="form-text-input-wrapper">
                  <label className="form-input-label" htmlFor="state">
                    {signUpForm.Labels.state}
                  </label>
                  <input
                    className="form-text-input-field"
                    id="state"
                    name="state"
                    onChange={handleInputChange}
                    type="text"
                  />
                </div>

                <div className="form-text-input-wrapper">
                  <label className="form-input-label" htmlFor="postalCode">
                    {signUpForm.Labels.postCode}
                  </label>
                  <input
                    className="form-text-input-field"
                    id="postalCode"
                    name="postalCode"
                    onChange={handleInputChange}
                    type="text"
                  />
                </div>

                {(signUpForm.CheckboxArea ?? []).map((checkbox) => (
                  <div
                    className="form-checkbox-wrapper"
                    key={checkbox.DatabaseFieldName}
                  >
                    <input
                      checked={state[checkbox.DatabaseFieldName]}
                      className="form-checkbox-input"
                      id={checkbox.DatabaseFieldName}
                      name={checkbox.DatabaseFieldName}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                    />

                    <label
                      className={`form-checkbox-label ${
                        !!state.error &&
                          checkbox.Required &&
                            !isFilled(checkbox.DatabaseFieldName)
                          ? 'form-error-message'
                          : ''
                      }`}
                      htmlFor={checkbox.DatabaseFieldName}
                    >
                      <Markdown>{checkbox.CheckboxLabel}</Markdown>
                    </label>
                  </div>
                ))}
              </div>

              <div
                className={`form-captcha-wrapper ${
                  !!state.error && !isFilled('reCaptchaVerification')
                    ? 'error-border'
                    : ''
                }`}
              >
                <Recaptcha
                  className="g-recaptcha"
                  onChange={verifyCallback}
                  ref={e => {
                    recaptchaInstance = e
                  }}
                  sitekey={captchaSiteKey}
                />
              </div>

              {state.errorMessage && <p className="form-error-message">{state.errorMessage}</p>}
              {state.isLoading && <p>...</p>}

              <input
                className="form-submit-button"
                disabled={state.isLoading}
                type="submit"
                value={signUpForm.Labels.button}
              />
            </form>
          </div>
        </div>
      </Panel>
    </>
  )
}

Form.propTypes = {
  backendUrl: PropTypes.string,
  captchaSiteKey: PropTypes.string,
  signUpForm: PropTypes.shape({
    CheckboxArea: PropTypes.array,
    Description: PropTypes.string,
    Labels: PropTypes.shape({
      button: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      email: PropTypes.string,
      emailAlreadyTaken: PropTypes.string,
      errorGeneric: PropTypes.string,
      formError: PropTypes.string,
      invalidEmail: PropTypes.string,
      name: PropTypes.string,
      postCode: PropTypes.string,
      state: PropTypes.string,
      streetAddress: PropTypes.string,
    }),
    Title: PropTypes.string,
  }),
}

export default Form

import React from 'react'
import PropTypes from 'prop-types'
import { FadeIn } from '../../'
import Markdown from 'react-markdown'

const TextWithImage = ({ data }) => (
  <div className="main-section retailer-details-section">
    <div
      className={`main-section-content ${
        data.Aligment.Aligment === 'image on the right' ? 'reversed-section' : ''
      } retailer-section ${data.customClass}`}
    >
      <div className="main-section-part retailer-section-part">
        <FadeIn delay={400} horizontal>
          <img
            alt={data.Image.data?.attributes?.alternativeText || ''}
            className="section-image retailer-image"
            src={data.Image.data?.attributes?.url}
          />
        </FadeIn>
      </div>

      <div className="main-section-part padded-text-content retailer-section-part second-section-part column">
        <FadeIn delay={800} horizontal>
          <div className="retailers-section-title">{data.Title ?? ''}</div>
          <div className="retailers-text">
            {data.Richtext && <Markdown>{data.Richtext}</Markdown>}
          </div>
        </FadeIn>
      </div>
    </div>
  </div>
)

TextWithImage.propTypes = {
  data: PropTypes.object,
}

export default TextWithImage

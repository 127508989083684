import React from 'react'

export const CarrefourPin = () => (
  <g
    className="retailer-pin"
    id="Group_1778_1_"
    transform="translate(-21 278.462)"
  >
    <g id="Group_1773_1_" transform="translate(0 0)">
      <linearGradient
        gradientTransform="matrix(65.0985 0 0 -55.3762 108221.1484 1653.2206)"
        gradientUnits="userSpaceOnUse"
        id="Path_7753-6_3_"
        x1="-1677.146"
        x2="-1676.5695"
        y1="31.9731"
        y2="32.8717"
      >
        <stop offset="0" style={{ stopColor: '#000000' }} />
        <stop offset="1" style={{ stopColor: '#F0F0F', stopOpacity: 0 }} />
      </linearGradient>
      <path
        className="st14"
        d="M-921.2-168.9c-15.6,0-33.2,7.5-39.4,16.7c-11.1,16.7,3.8,36.7,3.8,36.7
        s41.6-20,52.7-36.7C-897.9-161.4-905.5-168.9-921.2-168.9z"
        id="Path_7753-6_1_"
      />
      <g id="Group_762-6_1_" transform="translate(0 0)">
        <path
          className="st1"
          d="M-956.8-212.9c-22.3,0-41,20.5-41,37.2c0,30.4,41,60,41,60s39.9-29.6,39.9-60
          C-916.9-192.4-934.4-212.9-956.8-212.9z"
          id="Path_7754-6_1_"
        />
      </g>
    </g>
    <g id="carrefour_1_" transform="translate(9.791 21.007)">
      <path
        className="st15"
        d="M-980.4-212.3c0.1-0.1,0.2-0.1,0.3-0.2c0.2,0,0.3,0.1,0.3,0.3c0,0,0,0,0,0
        c0,0.1-0.1,0.2-0.1,0.3c-4.3,5.2-6.7,11.7-6.6,18.5c0,7.9,2.8,13.5,6.6,18.3c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.3-0.2,0.3
        c0,0,0,0,0,0c-0.1,0-0.2-0.1-0.3-0.2l-16.3-16.1c-1.5-1.2-1.8-3.3-0.6-4.9c0.2-0.2,0.4-0.4,0.6-0.6L-980.4-212.3"
        id="Path_7691_1_"
      />
      <path
        className="st16"
        d="M-967.9-213.7c-6.4,0-8.9,9.3-8.9,20.3s2.5,20.2,8.9,20.2c3.8,0,7.1-2.3,7.1-4.2
        c0-0.4-0.2-0.9-0.5-1.2c-1.5-1.3-2.4-3.2-2.5-5.2c0-2.8,2.1-5.1,4.9-5.3c3.3,0,5.2,2.6,5.2,6c-0.1,2.9-1.1,5.7-2.8,8
        c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.2-0.1,0.3-0.2l16.3-16.1c1.5-1.2,1.8-3.3,0.6-4.9
        c-0.2-0.2-0.4-0.4-0.6-0.6l-16.3-16.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.2,0.1-0.2,0.3c0,0.1,0,0.2,0.1,0.3
        c1.7,2.3,2.7,5.1,2.8,8c0,3.4-1.9,6-5.2,6c-2.8-0.2-4.9-2.5-4.9-5.3c0.1-2,1-3.9,2.5-5.2c0.3-0.3,0.5-0.7,0.5-1.2
        C-960.8-211.4-964-213.7-967.9-213.7z"
        id="Path_7692_1_"
      />
    </g>
  </g>
)

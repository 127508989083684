import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, SEO } from '../../components'

import './index.css'

const ConsumerPrivacyPage = () => {
  const { i18n } = useTranslation()

  return (
    <>
      <SEO title="Consumer Privacy" />
      <div className="container-lg">
        <div className="join-us-section">
          <div className="main-section-title our-mission-title primary-title"><Trans>title</Trans></div>
          <div>
            <Trans
              components={{
                linkmail: <Link className="pp-link" isDirectLink path={`mailto:${i18n.t('email')}`}></Link>,
                linkphone: <Link className="pp-link" isDirectLink path={`tel:${i18n.t('phone')}`}></Link>,
                linkpp: <Link className="pp-link" externalLink={i18n.t('pp_url')}></Link>,
              }}
              values={{
                email: i18n.t('email'),
                phone: i18n.t('phone'),
                pp:  i18n.t('pp'),
              }}
            >
              description
            </Trans>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConsumerPrivacyPage

import React from 'react'
import { Link } from '../../'
import homeImages from '../../../images/home'
import { retailerPins } from '../../../images/home-retailer-pins'
import '../index.css'
import './map.css'

const HomeRetailerMap = () => (
  <div className="retailer-map-container">
    <img
      alt="map with retailer logos"
      className="section-image padded-image-content animation-image-container"
      src={homeImages.retailerMapBackground}
    />
    <img
      alt="Heinz container"
      className="section-image padded-image-content haagen-dazs-container"
      src={homeImages.heinz}
    />
    <svg className="section-image retailer-map-logo-container animation-image"
      version="1.1"
      viewBox="0 0 770 770"
      x="0px"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
    >
      <g id="Retailers" xmlns="http://www.w3.org/2000/svg">
        <g id="retailer_map_pins_1_" transform="translate(1049.967 270.727)">
          {retailerPins.map(retailer => (
            <Link
              externalLink={retailer.externalLink}
              key={retailer.name}
              path={`/${retailer.path}`}
            >
              {retailer.component}
            </Link>
          ))}
        </g>
      </g>
    </svg>
  </div>
)

export default HomeRetailerMap

const mapStyles = [
  {
    elementType: 'labels.text',
    featureType: 'administrative',
    stylers: [
      {
        color: '#a3927d',
      },
      {
        visibility: 'simplified',
      },
      {
        weight: 0.5,
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'landscape.man_made',
    stylers: [
      {
        color: '#fbf3e5',
      },
    ],
  },
  {
    elementType: 'geometry.stroke',
    featureType: 'landscape.man_made',
    stylers: [
      {
        color: '#fbf3e5',
      },
    ],
  },
  {
    elementType: 'labels.text',
    featureType: 'landscape.man_made',
    stylers: [
      {
        visibility: 'simplified',
      },
      {
        weight: 0.5,
      },
    ],
  },
  {
    featureType: 'landscape.natural',
    stylers: [
      {
        color: '#fbf3e5',
      },
    ],
  },
  {
    elementType: 'labels.text',
    featureType: 'landscape.natural',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text',
    featureType: 'poi',
    stylers: [
      {
        color: '#a3927d',
      },
      {
        visibility: 'simplified',
      },
      {
        weight: 0.5,
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'poi.business',
    stylers: [
      {
        color: '#f0f0f0',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'geometry.stroke',
    featureType: 'poi.business',
    stylers: [
      {
        color: '#f0f0f0',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'poi.park',
    stylers: [
      {
        color: '#dbefab',
      },
    ],
  },
  {
    elementType: 'geometry.stroke',
    featureType: 'poi.park',
    stylers: [
      {
        color: '#dbefab',
      },
      {
        visibility: 'simplified',
      },
      {
        weight: 5.5,
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'poi.place_of_worship',
    stylers: [
      {
        color: '#dbefab',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'geometry.stroke',
    featureType: 'poi.place_of_worship',
    stylers: [
      {
        color: '#dbefab',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'poi.sports_complex',
    stylers: [
      {
        color: '#f0f0f0',
      },
    ],
  },
  {
    elementType: 'geometry.stroke',
    featureType: 'poi.sports_complex',
    stylers: [
      {
        color: '#f0f0f0',
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'road',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    elementType: 'geometry.stroke',
    featureType: 'road',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        visibility: 'simplified',
      },
      {
        weight: 0.5,
      },
    ],
  },
  {
    elementType: 'labels.text',
    featureType: 'road',
    stylers: [
      {
        color: '#a3927d',
      },
      {
        visibility: 'simplified',
      },
      {
        weight: 0.5,
      },
    ],
  },
  {
    elementType: 'labels.text',
    featureType: 'transit',
    stylers: [
      {
        color: '#a3927d',
      },
      {
        visibility: 'simplified',
      },
      {
        weight: 0.5,
      },
    ],
  },
  {
    featureType: 'water',
    stylers: [
      {
        color: '#9ceff7',
      },
    ],
  },
]

export default mapStyles


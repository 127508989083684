import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-markdown'
import { FadeIn } from '../../'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

const ContentText = ({ data }) => {
  const textAlign = data.TextAlign ?? 'left'

  return (
    <div className="main-section padded-text-content retailer-details-section retailers-text">
      <FadeIn delay={300}>
        <div className={`text-align-${textAlign} pagecontent-text-block ${data.IsSubtitle ? 'brand-page-content-title' : ''}`}>
          <Markdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>{data.Richtext}</Markdown>
        </div>
      </FadeIn>
    </div>
  )
}

ContentText.propTypes = {
  data: PropTypes.object,
}

export default ContentText

import React from 'react'
import PropTypes from 'prop-types'
import { FadeIn, Link, Separator } from '../../'

const BrandPageNavigation = ({ data }) => {
  if (!data)
    return null

  return (
    <FadeIn delay={900} horizontal>
      <div className="container-lg mt-5 mb-5">
        {(data || []).map(elem => {
          if (elem.Richtext?.length > 0) {
            return <div className="brand-page-navigation-title" key={elem.id}>{elem.Richtext}</div>
          }

          return null
        })}

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {(data || []).map(elem => {
            if (elem.Url) {
              return (
                <Link className="mx-3 mt-4 mb-4 button-link" key={elem.id} path={elem.Url}>
                  {elem.Label}
                </Link>
              )
            }

            return null
          })}
        </div>
      </div>
      <div className="container-lg mt-5">
        <Separator />
      </div>
    </FadeIn>
  )
}

BrandPageNavigation.propTypes = {
  data: PropTypes.array,
}

export default BrandPageNavigation

import React from 'react'
import PropTypes from 'prop-types'
import { useLoaderData } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { Button } from 'react-bootstrap'
import { Animation, FadeIn, HomeRetailerMap, Link, SEO, Separator, Slider } from '../../components'
import homeImages from '../../images/home'

import './index.css'

const IndexPage = () => {
  const { data: { data } } = useLoaderData()

  return (
    <>
      <SEO title="Loop - A Global Platform for Reuse" />

      <div id="animation-wrapper">
        <ShopSection />
        <EcoSystemSection data={data.attributes.HomePageCarousel} />
        <CleaningSection />
      </div>

      <PurposeSection />
      <PressSection />
    </>
  )
}

const ShopSection = () => (
  <div className="main-section-background shop-section-background">
    <Animation />

    <div className="main-section shop-section first-page-section">
      <div className="container-lg main-section-content" id="animation-helper-1">
        <div className="main-section-part padded-text-content home-shop-section">
          <FadeIn horizontal>
            <div className="main-section-title primary-title shop-section-title">
              <Trans>home.designedForReuse</Trans>
            </div>
          </FadeIn>

          <FadeIn delay={800}>
            <div className="main-section-text primary-text">
              <Trans>home.loopIsAGlobalReuse</Trans>
            </div>
          </FadeIn>

          <FadeIn delay={1100}>
            <Link path="/shop">
              <Button as="div" className="main-button" variant="primary">
                <Trans>home.experienceLoop</Trans>
              </Button>
            </Link>
          </FadeIn>
        </div>
        <div className="main-section-part"></div>
      </div>

      <div
        className="animate__animated animate__fadeIn animate__delay-2s"
      >
        <img
          alt="resusable packages"
          className="shop-section-image"
          src={homeImages.bannerImageMobile}
        />
      </div>
    </div>
  </div>
)

const EcoSystemSection = ({ data }) => (
  <div>
    <Slider images={data.HomePageCarouselImages.data} />

    <div className="ecosystem main-section-background">
      <div className="main-section">
        <div className="container-lg main-section-content">
          <div className="main-section-part padded-text-content">
            <FadeIn horizontal>
              <div className="main-section-title">
                <Trans>home.aGlobalReuseEcosystem</Trans>
              </div>
              <Separator
                style={{ backgroundColor: '#FFFFFF', marginBottom: '25px' }}
              />
            </FadeIn>

            <FadeIn delay={800}>
              <div className="main-section-text">
                <Trans>home.inPartnership</Trans>
              </div>
            </FadeIn>

            <FadeIn delay={1100}>
              <Link path="/partners">
                <Button as="div" className="main-button" variant="light">
                  <Trans>home.learnMore</Trans>
                </Button>
              </Link>
            </FadeIn>

          </div>
          <div className="main-section-part second-section-part" id="animation-helper-2">
            <FadeIn delay={100}>
              <HomeRetailerMap />
            </FadeIn>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const CleaningSection = () => (
  <div className="cleaning main-section-background">
    <div className="main-section">
      <div className="container-lg main-section-content">
        <div className="main-section-part padded-text-content">
          <FadeIn horizontal>
            <div className="main-section-title">
              <Trans>home.circularSupplyChain</Trans>
            </div>

            <Separator
              style={{ backgroundColor: '#076268', marginBottom: '25px' }}
            />
          </FadeIn>

          <div className="main-section-text" />

          <FadeIn delay={800}>
            <Trans>home.loopOperates</Trans>
          </FadeIn>
        </div>

        <div className="main-section-part second-section-part mobile-cleaning-image">
          <FadeIn>
            <img
              alt="cleaning system illustration"
              className="section-image padded-image-content"
              src={homeImages.heinzProductBgMobile}
            />
          </FadeIn>

        </div>

        <div className="main-section-part second-section-part relative" id="animation-helper-3">
          <FadeIn>
            <img
              alt="cleaning system illustration"
              className="section-image padded-image-content"
              src={homeImages.heinzProductBg}
            />
          </FadeIn>
        </div>
      </div>
    </div>
  </div>
)

const PurposeSection = () => (
  <div className="main-section-background">
    <div className="main-section">
      <div className="container-lg main-section-content">
        <div className="main-section-part padded-text-content">

          <FadeIn horizontal>

            <div className="main-section-title">
              <Trans>home.purpose</Trans>
            </div>
            <Separator
              style={{ backgroundColor: '#1ba0db', marginBottom: '25px' }}
            />
          </FadeIn>

          <FadeIn delay={800}>
            <div className="main-section-text">
              <Trans>home.loopIsBuilding</Trans>
            </div>
          </FadeIn>

          <FadeIn delay={1100}>
            <Link path="/purpose">
              <Button as="div" className="main-button" variant="primary">
                <Trans>home.discover</Trans>
              </Button>
            </Link>
          </FadeIn>
        </div>

        <div className="main-section-part second-section-part">
          <FadeIn>
            <img
              alt="turtle"
              className="section-image padded-image-content"
              src={homeImages.purposeImage}
            />
          </FadeIn>
        </div>
      </div>
    </div>
  </div>
)

const PressSection = () => (
  <div className="main-section-background press-section-background">
    <div className="main-section press-section">
      <div className="container-lg press-section-content">
        <div className="press-section-inner-content">
          <div className="press-section-quote  padded-text-content">
            <Trans>home.giantBrandsLove</Trans>
          </div>

          <img
            alt="Fast Company logo"
            className="fast-company-logo"
            src={homeImages.fastCompany}
          />

          <Link path="/press">
            <Button as="div" href="/press" variant="light">
              <Trans>home.seeMore</Trans>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  </div>
)

EcoSystemSection.propTypes = {
  data: PropTypes.object,
}

export default IndexPage

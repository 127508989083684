import facebook from './facebook.png'
import instagram from './instagram.png'
import linkedin from './linkedin.png'
import twitter from './twitter.png'
import youtube from './youtube.png'

const socialIcons = {
  facebook,
  instagram,
  linkedin,
  twitter,
  youtube,
}

export default socialIcons

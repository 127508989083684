import React from 'react'
import PropTypes from 'prop-types'
import { FadeIn } from '../../'

const FullWidthImage = ({ data }) => (
  <div className="main-section retailer-details-section">
    <FadeIn delay={500}>
      <img
        alt={data.Images.DesktopImage.data?.attributes?.alternativeText}
        className="section-fullwidth-image desktop-image"
        src={data.Images.DesktopImage?.data?.attributes.url}
      />

      <img
        alt={data.Images.MobileImage?.data?.attributes?.alternativeText}
        className="section-fullwidth-image mobile-image"
        src={data.Images.MobileImage?.data?.attributes?.url}
      />
    </FadeIn>
  </div>
)

FullWidthImage.propTypes = {
  data: PropTypes.object,
}

export default FullWidthImage

import challenge from './challenge.png'
import impact from './impact.jpg'
import loopDisplay from './loopDisplay.png'
import ourStory from './our-story.png'
import solution from './solution.png'
import tcFoundation from './tc-foundation.png'
import terracycleLogo from './terracycle-logo.png'
import vision from './vision.png'

const purposeImages = {
  challenge,
  impact,
  loopDisplay,
  ourStory,
  solution,
  tcFoundation,
  terracycleLogo,
  vision,
}

export default purposeImages

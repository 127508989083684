import React from 'react'
import { Helmet } from 'react-helmet'
import { useOutletContext } from 'react-router-dom'
import { Carousel } from 'react-bootstrap'
import { Section } from '../../../components'
import { appLogo } from '../../../images'

const MobileAppPage = () => {
  const data = useOutletContext()

  return (
    <>
      <Helmet
        metaArray={[
          { content: data.SeoDescription , name: 'description' },
          { content: data.SeoDescription , property: 'og:description' },
          { content: data.SeoTitle , property: 'og:title' },
        ]}
        title={data.SeoTitle}
      />

      <img alt="Loop Mobile app" className="logo mx-auto d-block" src={appLogo} />

      <div className="container loop-blue-color font-weight-bold">

        {React.Children.toArray(
          (data.Content ?? []).map(section => <Section data={section} />),
        )}

        <div className="row mt-5">
          <div className="col-md-12 mt-5">
            <p>
              {data.translations && data.translations['mobile_app_page.app_preview']}
            </p>
          </div>

          {React.Children.toArray(
            (data.AppReview ?? []).map(appReview => (
              <div className="col-md-3 d-none d-md-block">
                <img alt={appReview.Image.data.attributes.alternativeText || ''} className="img-fluid app-review-image" src={appReview.Image.data.attributes.url} />
                <p className="text-center font-weight-normal mt-3">
                  {appReview.Title}
                </p>
              </div>
            )),
          )}

          <div className="col-md-12 d-md-none">
            <Carousel controls={false}>
              {React.Children.toArray(
                (data.AppReview ?? []).map(appReview => (
                  <Carousel.Item>
                    <img alt={appReview.Image.data.attributes.alternativeText || ''} className="img-fluid app-review-image" src={appReview.Image.data.attributes.url} />
                    <p className="text-center font-weight-normal mt-3">
                      {appReview.Title}
                    </p>
                  </Carousel.Item>
                )),
              )}
            </Carousel>
          </div>

        </div>
      </div>

      {React.Children.toArray(
        (data.FooterContent ?? []).map(section => <Section data={section} />),
      )}
    </>
  )
}

export default MobileAppPage

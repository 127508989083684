import { loopEcosystem } from '../../images'
import purposeImages from '../../images/purpose'

export const ourMissionContent = [
  {
    image: purposeImages.challenge,
    text:
      ['theProliferationOfSingleUse'],
    title: 'challenge',
  },
  {
    image: purposeImages.solution,
    text:
      ['loopSModelIsBuilt'],
    title: 'solution',
  },
  {
    image: purposeImages.impact,
    text:
      ['ourCommitmentToTransparency'],
    title: 'impact',
  },
  {
    image: loopEcosystem,
    text:
      ['ourVisionIsA'],
    title: 'vision',
  },
]

import React from 'react'
import { AeonPin } from './aeon-pin.js'
// import { BurgerKingPin } from './burger-king-pin.js'
import { CarrefourPin } from './carrefour-pin.js'
import { NutellaPin } from './nutella-pin.js'
import { MonoprixPin } from './monoprix-pin.js'
import { CocaColaPin } from './cocacola-pin.js'
// import { TescoPin } from './tesco-pin.js'
// import { WalmartPin } from './walmart-pin.js'
// import { TimHortonsPin } from './tim-hortons-pin.js'
// import { UltaPin } from './ulta-pin.js'
import { DrBronnerPin } from './drbronner-pin.js'
import { EcosPin } from './ecos-pin.js'
import { TidePin } from './tide-pin.js'

export const retailerPins = [
  {
    component: <AeonPin />,
    name: 'Aeon',
    path: 'Aeon',
  },
  {
    component: <EcosPin />,
    name: 'Ecos',
    path: '#',
  },
  {
    component: <CarrefourPin />,
    name: 'Carrefour',
    path: 'distributeurs',
  },
  {
    component: <NutellaPin />,
    name: 'Nutella',
    path: '#',
  },
  {
    component: <MonoprixPin />,
    name: 'Monoprix',
    path: 'distributeurs',
  },
  {
    component: <TidePin />,
    name: 'Tide',
    path: '#',
  },
  {
    component: <CocaColaPin />,
    name: 'CocaCola',
    path: '#',
  },
  // {
  //   component: <WalmartPin />,
  //   name: 'Walmart',
  //   path: 'Walmart',
  // },
  // {
  //   component: <UltaPin />,
  //   name: 'Ulta',
  //   path: 'Ulta',
  // },
  {
    component: <DrBronnerPin />,
    name: 'DrBronner\'s',
    path: '#',
  },
]

import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from '../'
import icons from '../../images/footer/social-icons'

import strapiInstance from '../../api'

import './index.css'

const Footer = () => {
  const { i18n } = useTranslation()

  const [footerLinks, setFooterLinks] = useState([])

  useEffect(() => {
    strapiInstance.get(`/footer-link?populate=*&locale=${i18n.language}`)
      .then(({ data }) => {
        setFooterLinks(data.data.attributes.FooterLinks)
      })
      .catch(error => {
        console.error('Error:', error)
      })
  },[i18n.language])

  return (
    <div className="footer-outer-container">
      <div className="container-lg footer-inner-container">
        <div className="column footer-section links">
          <Link path="/join">
            <Trans>footer.contactUs</Trans>
          </Link>
          <Link path="/press"><Trans>footer.loopPress</Trans></Link>
          <Link path="/partners"><Trans>footer.brandPartners</Trans></Link>
          <button className="ot-sdk-show-settings" id="ot-sdk-btn">Cookie Settings</button>
        </div>
        <div className="column footer-section social-links">
          <div className="footer-section-title"><Trans>footer.stayInTouch</Trans></div>
          <div className="social-networks">
            {footerLinks.map(({ Label, Url }) => (
              <Link
                className="social-network"
                externalLink={Url}
                key={Label}
              >
                <img
                  alt={Label}
                  className="social-icon"
                  src={icons[Label]}
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <span>&copy; {new Date().getFullYear()} </span>
        <span><Trans>footer.allRights</Trans></span>
      </div>
    </div>
  )
}

export default Footer

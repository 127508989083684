import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { FadeIn, SEO } from '../../components'
import errorIllustration from '../../images/404'

import './index.css'

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />

    <div className="container-lg error-page">
      <FadeIn>
        <h1 className="error-page-title"><Trans>error.404</Trans></h1>

        <img alt="404-not-found-img" className="error-image" src={errorIllustration} />

        <div>
          <p className="error-page-subtitle">
            <Trans>error.desc1</Trans>
          </p>
          <p>
            <Trans>error.desc2</Trans>
          </p>
        </div>
      </FadeIn>
    </div>
  </>
)

NotFoundPage.propTypes = {
  location: PropTypes.object,
}

export default NotFoundPage
